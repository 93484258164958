<div *ngIf="!overLay()">
  <ng-container *ngIf="!isPreSubmissionScreen; else ShowPreSubmission">
    <div class="add-scroll d-flex try-project-container">
      <div class="left-content">
        <scrollbar class="scrollbar">
          <div class="left-content-scroll">
            <div class="accordion project-player-accordian projects-accordion mb-16"
              id="accordionPanelsStayOpenExample">
              <div class="accordion-item project-player-accordian">
                <h2 class="accordion-header" id="panelsStayOpen-heading">
                  <button
                    class="accordion-button d-flex align-items-center justify-content-between heading-2  project-player-accordian-button"
                    type="button">
                    {{attemptDataDetails.projects.length}} Projects
                    <span class="accordion-toggle-icon cursor-pointer ms-1" data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapse" aria-expanded="true"
                      aria-controls="panelsStayOpen-collapse">
                    </span>
                  </button>
                </h2>
                <div id="panelsStayOpen-collapse" class="accordion-collapse collapse show"
                  aria-labelledby="panelsStayOpen-heading">
                  <scrollbar class="scrollbar">
                    <div class="accordion-body p-16">

                      <div class="project-list pb-16">
                        <div class="single-project d-flex align-items-center"
                          *ngFor="let project of attemptDataDetails.projects; let i= index">
                          <div class="d-flex align-items-center p-2">
                            <img class="project-attempt-status-icon"
                              *ngIf="project.title !== currentProject.title && projectPlayerService.getAnswerStatus(project.projectId)"
                              src="../../../../assets/images/projectPlayer/answered.svg">
                            <img class="project-attempt-status-icon" *ngIf="project.title === currentProject.title"
                              src="../../../../assets/images/projectPlayer/ongoing.svg">
                            <img class="project-attempt-status-icon"
                              *ngIf="project.title !== currentProject.title && !projectPlayerService.getAnswerStatus(project.projectId)"
                              src="../../../../assets/images/projectPlayer/unanswered.svg">
                            <span class="heading-2 ms-2">{{i+1}}</span>
                          </div>
                          <div class="project-name text-break d-flex align-items-center flex-fill p-2"
                            (click)="submitCurrentProjectAndSwitchProject(project, i)">
                            {{project.title}}
                            <span (click)="$event.stopPropagation();markForReview(project.projectId)"><img
                                *ngIf="!projectPlayerService.getMarkedForReview(project.projectId)"
                                src="../../../../assets/images/projectPlayer/unbookmark.svg">
                              <img *ngIf="projectPlayerService.getMarkedForReview(project.projectId)"
                                src="../../../../assets/images/projectPlayer/for-review.svg"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </scrollbar>
                  <div class="accordion-footer">
                    <div>
                      <img src="../../../../assets/images/projectPlayer/answered.svg">
                      <p class="small-assistive-text">{{projectPlayerService.AnswerStatusCount()}} Answered</p>
                    </div>
                    <div>
                      <img src="../../../../assets/images/projectPlayer/unanswered.svg">
                      <p class="small-assistive-text">
                        {{attemptDataDetails.projects.length-projectPlayerService.AnswerStatusCount()}} Unanswered</p>
                    </div>
                    <div>
                      <img src="../../../../assets/images/projectPlayer/for-review.svg">
                      <p class="small-assistive-text">{{projectPlayerService.markedForReviewCount()}} For Review</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion project-player-accordian lang-file" id="accordionPanelsStayOpenExample1">
              <div class="accordion-item project-player-accordian">
                <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                  <button
                    class="accordion-button d-flex align-items-center justify-content-between heading-2  project-player-accordian-button"
                    type="button" translate="projectBank.projectPlayer.languageFileHeading">
                    <span class="accordion-toggle-icon cursor-pointer ms-1 collapsed" data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseOne">
                    </span>
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse "
                  aria-labelledby="panelsStayOpen-headingOne">
                  <div class="accordion-body p-0">
                    <app-file-explorer [mode]="mode">
                    </app-file-explorer>
                  </div>
                </div>
              </div>
            </div>

            <div class="project-clear-instructions">

              <scrollbar class="scrollbar">
                <app-project-details [mode]="mode"></app-project-details>
              </scrollbar>
            </div>
          </div>
        </scrollbar>

      </div>

      <div class="right-content">

        <div class="try-project-header d-flex justify-content-between">

          <div class="heading-1 d-flex align-items-center">
            {{currentProject.title}}<span
              class="mark-card big-bold-assistive-text ml-16">{{currentProject.maxMarks}}<span>
                Mark</span></span>
          </div>
          <div class="d-flex align-items-center header-band">

            <div class="last-saved d-inline-flex flex-column">
              <div class="d-flex align-items-center justify-content-end cursor-pointer" (click)="saveFile()">
                <img src="../../../../assets/images/projectPlayer/save.svg"><span class="big-bold-assistive-text ms-2"
                  translate="projectBank.projectPlayer.tryProject.save"></span>
              </div>
              <p class="m-0">
                Last saved at {{(projectPlayerService.getLastSavedStatus(currentProject.projectId) | date:'h:mm a')||
                (defaultTime | date :'h:mm a')}}
              </p>
            </div>

            <div class="time-left level-easy"
              *ngIf="attemptDataDetails.exerciseKind == 'quiz' && attemptDataDetails.timeLimit !== 0">
              Time left - {{ (projectPlayerService.countdown$ | async) || '00:00:00' }}
            </div>

            <div class="dropdown flat-btn theme-dropdown">
              <span class="dropdown-toggle align-items-center cursor-pointer d-flex" data-bs-toggle="dropdown"
                aria-expanded="false">
                <img class="me-1" src="../../../../assets/images/theme.svg">
                <p translate="projectBank.projectPlayer.tryProject.theme"></p>
              </span>

              <ul class="dropdown-menu">
                <li>
                  <a class="dropdown-item" (click)="toggleTheme('light')"
                    [ngClass]="selectedStatus ? 'theme-not-selected' : 'theme-selected'">
                    <svg-icon class="theme-selected-icon pe-2" src="../../../../assets/images/tick.svg"></svg-icon>
                    <svg-icon class="me-2"
                      src="../../../../assets/images/projectPlayer/light_theme_icon.svg"></svg-icon>
                    <p translate="projectBank.projectPlayer.tryProject.light"></p>
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" (click)="toggleTheme('dark')"
                    [ngClass]="selectedStatus ? 'theme-selected' : 'theme-not-selected'">
                    <svg-icon class="theme-selected-icon pe-2" src="../../../../assets/images/tick.svg"></svg-icon>
                    <svg-icon class="me-2" src="../../../../assets/images/projectPlayer/dark_theme_icon.svg"></svg-icon>
                    <p translate="projectBank.projectPlayer.tryProject.dark"></p>
                  </a>
                </li>
              </ul>
            </div>

            <div class="dropdown flat-btn ml-16 output-dropdown">
              <span class="dropdown-toggle cursor-pointer d-flex" data-bs-toggle="dropdown" aria-expanded="false">
                <img class="me-1" src="../../../../assets/images/output.svg">
                <p translate="projectBank.projectPlayer.tryProject.output"></p>
              </span>
              <ul class="dropdown-menu">
                <li>
                  <a class="dropdown-item" (click)="outputLayout(layout.SIDE)"
                    [ngClass]="{'selected-layout': projectPlayerService.layout == layout.SIDE}">
                    <svg-icon class="selected-layout-icon pe-2" src="../../../../assets/images/tick.svg"></svg-icon>
                    <svg-icon class="me-2" src="../../../../assets/images/projectPlayer/side_panel_icon.svg"></svg-icon>
                    <p translate="projectBank.projectPlayer.tryProject.sidePanel"></p>
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" (click)="outputLayout(layout.DOWN)"
                    [ngClass]="{'selected-layout': projectPlayerService.layout == layout.DOWN}">
                    <svg-icon class="selected-layout-icon pe-2" src="../../../../assets/images/tick.svg"></svg-icon>
                    <svg-icon class="me-2" src="../../../../assets/images/projectPlayer/down_panel_icon.svg"></svg-icon>
                    <p translate="projectBank.projectPlayer.tryProject.downPanel"></p>
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" (click)="outputLayout(layout.PICTURE)"
                    [ngClass]="{'selected-layout': projectPlayerService.layout == layout.PICTURE}">
                    <svg-icon class="selected-layout-icon pe-2" src="../../../../assets/images/tick.svg"></svg-icon>
                    <svg-icon class="me-2" src="../../../../assets/images/projectPlayer/picture_in_picture_icon.svg">
                    </svg-icon>
                    <p translate="projectBank.projectPlayer.tryProject.pictureInPicture"></p>
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" (click)="outputLayout(layout.NONE)"
                    [ngClass]="{'selected-layout': projectPlayerService.layout == layout.NONE}">
                    <svg-icon class="selected-layout-icon pe-2" src="../../../../assets/images/tick.svg"></svg-icon>
                    <svg-icon class="me-2" src="../../../../assets/images/projectPlayer/none_icon.svg"></svg-icon>
                    <p translate="projectBank.projectPlayer.tryProject.none"></p>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <app-file-editor [mode]="mode"></app-file-editor>
      </div>

    </div>
    <!-- 
  <div class="d-flex justify-content-end footerBtn" *ngIf="mode === ExercisePlayerModes.TRY">
    <button type="button" class="btn btn-secondary-orange mx-4"
    translate="projectBank.projectPlayer.tryProject.editProject"></button>
    <button type="button" class="btn btn-primary" (click)="exitTrial()"
    translate="projectBank.projectPlayer.tryProject.exitTrial"></button>
  </div> -->

    <div class="d-flex justify-content-between footerBtn">
      <button type="button" class="btn btn-secondary-orange mx-4" (click)="navigateToPreSubmission()"
        translate="projectBank.projectPlayer.tryProject.summary&SubmitExercise"></button>

      <div class="pagination d-flex align-items-center">
        <div (click)="navigateProject('previous')" [ngClass]="currentProjectIndex === 0 ? 'btn-disabled' : 'next-btn'">
          <svg-icon class="me-2" *ngIf="currentProjectIndex === 0"
            src="../../../../assets/images/pagination/previous_pagination_grey.svg"></svg-icon>
          <svg-icon class="me-2" *ngIf="currentProjectIndex !== 0"
            src="../../../../assets/images/pagination/previous_pagination_orange.svg"></svg-icon>
          Previous Project
        </div>
        <div class="current-btn mx-2">
          {{ currentProjectIndex + 1 }}<span>/{{ attemptDataDetails.projects.length }}</span>
        </div>
        <div (click)="navigateProject('next')"
          [ngClass]="currentProjectIndex === attemptDataDetails.projects.length - 1 ? 'btn-disabled' : 'next-btn'">
          Next Project
          <svg-icon class="ms-2" *ngIf="currentProjectIndex === attemptDataDetails.projects.length - 1"
            src="../../../../assets/images/pagination/next_pagination_grey.svg"></svg-icon>
          <svg-icon class="ms-2" *ngIf="currentProjectIndex !== attemptDataDetails.projects.length - 1"
            src="../../../../assets/images/pagination/next_pagination_orange.svg"></svg-icon>
        </div>
      </div>

      <button type="button" class="btn btn-primary" translate="projectBank.projectPlayer.tryProject.submitProject"
        (click)="submitProjectModal()"></button>
    </div>
  </ng-container>
</div>


<ng-template #ShowPreSubmission>
  <app-pre-submission [attemptDataDetailsProjects]="attemptDataDetails.projects" [selectedTheme]="currentTheme"
    [exercisePlayerService]=" projectPlayerService" [kind]="attemptDataDetails.exerciseKind || 'quiz'"
    (emitBackToTest)=" backToTest($event)" (emitBackTheme)="toggledThemeStatus($event)"></app-pre-submission>
</ng-template>

<div *ngIf="overLay()">
  <app-overlay-skeleton dynamicTag="Submission in Progress"></app-overlay-skeleton>
</div>