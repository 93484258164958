export const environment = {
  production: false,
  product: 'unextlabs',
  environment: 'dev',
  gatewaySocketUrl: 'wss://unextlabs-performance.unext.tech/api/gateway-service/',
  nchanEventSourceUrl: 'https://unextlabs-performance.unext.tech/sub/',
  apiBaseUrl: 'https://unextlabs-performance.unext.tech',
  lumenapiBaseUrl: 'https://lumen-dev-api.unext.tech',
  rpDomain: 'rplt01',
  s3Bucket: 'https://ckeditor-lumen-nonprod.s3.ap-south-1.amazonaws.com/'
};
