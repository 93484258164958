/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription, map, takeUntil } from 'rxjs';
import { EContentType, ProjectPlayerModes, content } from 'src/app/enums/PlayerModes';
import { DBpayload, DatabaseCredentials } from 'src/app/models/attemptInterface';
import { GetDirectory, GetProjectFileContent, IDeleteDirectory, Tree, exportFileToMonacoEditor } from 'src/app/models/projectInterface';
import { ProjectPlayerService } from 'src/app/shared/modules/player/project-player.service';
import { DirectoryCreationComponent } from '../../directory-creation/directory-creation.component';
import { DialogService } from 'src/app/services/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { Dialog } from 'src/app/models/dialog';
import { DialogTypes } from 'src/app/enums/dialog';
import { Archetype } from 'src/app/models/project-list-card';

@Component({
  selector: 'app-file-explorer',
  templateUrl: './file-explorer.component.html',
  styleUrls: ['./file-explorer.component.scss']
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class FileExplorerComponent implements OnInit {
  dbName!: string;
  userName!: string;
  password!: string;
  archetypesWithDbCredentials = [
    'JAVA8_JDBC_ORACLE',
    'JAVA8_JDBC_MYSQL',
    'JAVA8_JDBC_MSSQL',
    'JAVA8_JDBC_PLSQL',
    'JAVA11_JDBC_ORACLE',
    'JAVA11_JDBC_MYSQL',
    'JAVA11_JDBC_MSSQL',
    'JAVA11_JDBC_PLSQL',
    'JAVA17_JDBC_ORACLE',
    'JAVA17_JDBC_MYSQL',
    'JAVA17_JDBC_MSSQL',
    'JAVA17_JDBC_PLSQL',
    'MSSQL',
    'MYSQL',
    'ORACLE',
    'PLSQL',
    'CSHARP11_ADO',
    'MYSQL_LARGE_DATASET',
    'ORACLE_LARGE_DATASET'
  ];

  @Input()
  public name!: string;
  public status!: string;

  archetypes: { name: string; value: string; }[] = [];
  public treeData!: Tree;
  kind!: string;
  fileContent!: string;
  selectedDirectory!: any;
  selectedArchType!: string;
  sourceFileName!: string;
  fileToSendToEditor!: exportFileToMonacoEditor;
  @Input() mode!: ProjectPlayerModes;
  ProjectPlayerModes = ProjectPlayerModes;
  selectedItem: any;
  unSubscribe = new Subject<void>();
  selectedLang!: string;
  archetype: any;
  isResultContent = false;
  modalRef!: NgbModalRef;
  mainFileNotFoundNotifier!: Subscription;

  // eslint-disable-next-line max-params
  constructor(public projectPlayerService: ProjectPlayerService, public toastService: ToastrService, private modal: NgbModal, private dialogService: DialogService, private translationService: TranslateService) {
  }
  ngOnInit(): void {
    this.loadDependencies();
    if (this.mode === ProjectPlayerModes.ATTEMPT || this.mode === ProjectPlayerModes.EVALUATION) {
      this.projectPlayerService.listenProjectChange().pipe(takeUntil(this.unSubscribe)).subscribe(() => {
        this.loadDependencies();
      });
    }
  }

  getCopyText(key: string): string {
    if (key === 'Username') {
      return this.userName;
    } else if (key === 'Password') {
      return this.password;
    } else if (key === 'DB Name') {
      return this.dbName;
    }
    // const message = `The credentials are not copied. Please try again.`;
    // this.showErrorToast(message); //please do not un comment
    return '';
  }

  public notify(credentialsName: string): void {
    const message = `'${credentialsName}' is successful copied`;
    this.showSuccessToast(message);
    this.status = message;
  }

  loadDependencies() {
    this.archetype = this.projectPlayerService.archetypes.map(item => item.archetypeId);
    this.capitalizedAndSplitArchOptions(this.archetype);
    this.archetypes.length ? this.selectedArchType = this.archetypes[0].value : '';
    this.storeSelectedArchetypeAndLangIdInService();
    this.projectPlayerService.listenHighLightActiveFile().pipe(takeUntil(this.unSubscribe)).subscribe((obj) => {
      this.comparePath(obj);
    });
    // const sanitize = await firstValueFrom(this.projectPlayerService.getResult$);
    // this.getResultFileContent(sanitize);

    this.projectPlayerService.getResult$.pipe(takeUntil(this.unSubscribe)).subscribe((sanitize: boolean) => {
      this.getResultFileContent(sanitize);
    });
  }

  storeSelectedArchetypeAndLangIdInService() {
    this.projectPlayerService.clearTab();
    this.projectPlayerService.customInput = {};
    this.projectPlayerService.selectedArchetype = this.selectedArchType;
    this.projectPlayerService.archetypes.map((version: { language: string, archetypeId: string, label: string, targetFileName: string, sourceFileName: string }) => {
      if (this.selectedArchType === version.archetypeId) {
        this.projectPlayerService.selectedLanguage = version.language;
        this.selectedLang = version.language;
        this.sourceFileName = version.sourceFileName;
        const archetypeOnSummary = `${version.language} > ${version.label}`;
        this.projectPlayerService.setLastSavedArchetype(this.projectPlayerService.projectId, archetypeOnSummary);
      }
    });
    this.mainFileNotFoundListner();
    this.getDirectoryDetails();
    if (this.archetypesWithDbCredentials.some((archetype: string) => archetype === this.selectedArchType)) {
      this.getDbCredentials();
    }
  }

  getDbCredentials() {
    const obj: DBpayload = {
      archetypeId: this.projectPlayerService.selectedArchetype,
      [this.mode !== ProjectPlayerModes.PRACTISE ? 'projectId' : 'practiceId']: this.projectPlayerService.projectId,
      ...(this.projectPlayerService.exerciseIdTry && { exerciseId: this.projectPlayerService.exerciseIdTry }),
    };
    if (this.mode === ProjectPlayerModes.ATTEMPT) {
      obj.attemptId = this.projectPlayerService.attemptId;
      console.log(obj.attemptId, "id");
    }
    if (this.mode === ProjectPlayerModes.EVALUATION) {
      this.projectPlayerService.archetypes.map((version: Archetype) => {
        if ((this.selectedArchType === version.archetypeId) && (version.dbName && version.password)) {
          this.dbName = this.userName = version.dbName;
          this.password = version.password;
          return
        }
      });
    }
    this.projectPlayerService.dbCredentials(obj).subscribe((res: DatabaseCredentials) => {
      this.dbName = res.dbName;
      this.userName = res.userName;
      this.password = res.password;
    });
  }

  getDirectoryDetails() {
    const obj: GetDirectory = {
      archetypeId: this.projectPlayerService.selectedArchetype,
      [this.mode !== ProjectPlayerModes.PRACTISE ? 'projectId' : 'practiceId']: this.projectPlayerService.projectId,
      ...(this.projectPlayerService.exerciseIdTry && { exerciseId: this.projectPlayerService.exerciseIdTry }),
    };
    const storeDirectorySubscription = this.projectPlayerService.getDirectoryDetails(obj)
      .pipe(
        map((directoryDetails: Tree) => {
          console.log('got directory', directoryDetails);
          return directoryDetails;
        })
      ).subscribe((directoryDetails: Tree) => {
        storeDirectorySubscription.unsubscribe();
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.addPath(directoryDetails.files!);
        this.treeData = directoryDetails;
        this.projectPlayerService.treeDataSolution = this.treeData;
        if (this.treeData) {
          const sourceFile = this.treeData.files?.find(file =>
            file.kind === 'directory' &&
            file.name === 'src' &&
            file.files
          )?.files?.find(element =>
            element.name === this.sourceFileName &&
            element.kind === 'file'
          );
          if (sourceFile) {
            this.displayFileInMonacoEditor(sourceFile);
          }
        }
      });
  }

  capitalizedAndSplitArchOptions(options: string[]) {
    this.archetypes = options.map(arch => {
      const nameParts = arch.split('_');
      const capitalizedParts = nameParts.map(part => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase());
      const name = capitalizedParts.join(' ');
      const value = arch;
      return { name, value };
    });
  }

  addPath(files: Tree[], basePath = "") {
    files.forEach((item) => {
      if (item.name) {
        item.path = `${basePath}${item.name}`;
      }
      if (item.files) {
        const nestedPath = `${basePath}${item.name}/`;
        this.addPath(item.files, nestedPath);
      }
    });
  }

  async selectItem(item: Tree) {
    item.kind === 'file' ? await this.displayFileInMonacoEditor(item) : this.selectDirectory(item);
  }

  selectDirectory(item: Tree) {
    this.selectedDirectory = item;
    if (!this.selectedDirectory.files) {
      this.selectedDirectory.files = [];
    }
  }

  addFileOrDirectory(kind: string, contentMode = content.CREATE) {
    this.kind = kind;
    this.addFileOrDirectoryFailure();
    if (this.selectedDirectory) {
      this.directoryManipulation(kind, contentMode);
    }

  }

  addFileOrDirectoryFailure() {
    this.projectPlayerService.addFileOrDirectoryFailure().subscribe(() => {
      setTimeout(() => {
        this.addFileOrDirectory(this.kind);
      }, 2000);
    });
  }

  displayFileInMonacoEditor(item: Tree) {
    this.selectedItem = item;
    const payload: GetProjectFileContent = {
      archetypeId: this.selectedArchType,
      [this.mode !== ProjectPlayerModes.PRACTISE ? 'projectId' : 'practiceId']: this.projectPlayerService.projectId,
      path: item.path as unknown as string,
      ...(this.projectPlayerService.exerciseIdTry && { exerciseId: this.projectPlayerService.exerciseIdTry }),
    };
    this.fileToSendToEditor = {
      name: item.name,
      path: item.path as unknown as string
    };
    this.listenToFileContent(payload);
  }

  async listenToFileContent(requiredFile: GetProjectFileContent) {
    const requiredContent = this.projectPlayerService.requestProjectFileContent(requiredFile).subscribe((data) => {
      const view = ["solution/output/result.png", "src/output/result.png"].includes(requiredFile.path) ? true : false;
      if (this.isResultContent) {
        this.projectPlayerService.triggerDisplayResultFile(data.fileContent);
        this.isResultContent = false;
        requiredContent.unsubscribe();
      } else if (!view) {
        this.fileToSendToEditor.code = data.fileContent;
        this.projectPlayerService.triggerDisplayFileInMonacoEditor(this.fileToSendToEditor);
        requiredContent.unsubscribe();
      }
      this.mainFileNotFoundNotifier.unsubscribe();
    });
  }

  comparePath(activeItem: exportFileToMonacoEditor) {
    for (const treeFile of this.treeData.files || []) {
      if (treeFile.files) {
        for (const file of treeFile.files) {
          if (file.path === activeItem.path) {
            this.selectedItem = file;
            return;
          }
        }
      }
    }
  }

  showSuccessToast(message: string): void {
    this.toastService.success(message, '', {
      positionClass: 'toast-top-center',
      closeButton: true,
      timeOut: 5000,
      extendedTimeOut: 5000,
      tapToDismiss: false
    });
  }

  showErrorToast(message: string): void {
    this.toastService.error(message, '', {
      positionClass: 'toast-top-center',
      closeButton: true,
      timeOut: 5000,
      extendedTimeOut: 5000,
      tapToDismiss: false
    });
  }

  getResultFileContent(sanitize: boolean) {
    this.isResultContent = true;
    const contentPath = sanitize ? 'solution/output/result.png' : 'src/output/result.png';
    const payload: GetProjectFileContent = {
      archetypeId: this.selectedArchType,
      [this.mode !== ProjectPlayerModes.PRACTISE ? 'projectId' : 'practiceId']: this.projectPlayerService.projectId,
      path: contentPath as unknown as string,
      ...(this.projectPlayerService.exerciseIdTry && { exerciseId: this.projectPlayerService.exerciseIdTry }),
    };
    this.listenToFileContent(payload);
  }

  directoryManipulation(kind: string, contentMode: string, item?: Tree) {
    this.modalRef = this.modal.open(DirectoryCreationComponent, { backdrop: 'static', centered: true, animation: true, windowClass: "test-case-modal" });
    this.modalRef.componentInstance.contentMode = contentMode;
    this.modalRef.componentInstance.projectPlayerService = this.projectPlayerService;
    if (contentMode === content.EDIT) {
      this.modalRef.componentInstance.selectedDirectory = item;
    } else {
      this.modalRef.componentInstance.selectedDirectory = this.selectedDirectory;
    }
    this.modalRef.componentInstance.mode = this.mode;
    this.modalRef.componentInstance.kind = kind;
    this.modalRef.componentInstance.selectedArchType = this.selectedArchType;
    this.modalRef.componentInstance.addDirectory.subscribe((newFileOrDirectory: { kind: string, name: string, path: string, isSystemGenerated: boolean }) => {
      if (newFileOrDirectory) {
        this.selectedDirectory.files.push(newFileOrDirectory);
      }
      this.modalRef.close();
    });
    this.modalRef.componentInstance.editDirectory.subscribe((renamedContent: { name: string, path: string }) => {
      if (renamedContent) {
        this.changeNameByPath(this.treeData, renamedContent.path, renamedContent.name);
      }
      this.modalRef.close();
    });
  }

  changeNameByPath(node: Tree, targetPath: string, newName: string): void {
    if (node.path === targetPath) {
      node.name = newName;
      node.path = this.updatePath(node.path, newName);
      return;
    }
    if (node.files) {
      for (const file of node.files) {
        this.changeNameByPath(file, targetPath, newName);
      }
    }
  }

  updatePath(currentPath: string, newName: string): string {
    const parts = currentPath.split('/');
    parts[parts.length - 1] = newName;
    return parts.join('/');
  }

  async deleteDirectoryOrFile(item: Tree) {
    let message!: string;
    let note!: string;
    const messageTranslate = item.kind === 'directory' ? 'projectBank.projectPlayer.deleteFolder' : 'projectBank.projectPlayer.deleteFile';
    this.translationService.get(messageTranslate, { type: DialogTypes.ERROR, itemName: item.name }).subscribe((translation) => {
      message = translation;
    }).unsubscribe();
    const noteTranslate = item.kind === 'directory' ? 'projectBank.projectPlayer.noteDeleteFolder' : 'projectBank.projectPlayer.noteDeleteFile';
    this.translationService.get(noteTranslate).subscribe((translation) => {
      note = translation;
    }).unsubscribe();
    const dialog: Dialog = { title: { translationKey: message }, note: { translationKey: note } };
    const confirmation = await this.dialogService.showConfirmDialog(dialog);
    if (confirmation) {
      const deletePayload: IDeleteDirectory = {
        path: item.path,
        projectId: this.projectPlayerService.projectId,
        archetypeId: this.selectedArchType,
        ...(this.projectPlayerService.exerciseIdTry && { exerciseId: this.projectPlayerService.exerciseIdTry }),
      };
      const unsubscribeDeleteFileOrDirectory = this.projectPlayerService.DeleteFileFromTree(deletePayload).subscribe(() => {
        this.removeFileByPath(this.treeData, item.path);
        if (item.kind === EContentType.FILE) {
          this.projectPlayerService.deleteFileChecker.next(item.path);
        }
        unsubscribeDeleteFileOrDirectory.unsubscribe();
      });
    }
  }

  removeFileByPath(node: Tree, targetPath = ''): void {
    if (!node.files) {
      return;
    }
    node.files = node.files.filter(file => file.path !== targetPath);
    node.files.forEach(file => this.removeFileByPath(file, targetPath));
  }

  editDirectoryOrFile(item: Tree) {
    this.directoryManipulation(item.kind, content.EDIT, item);
  }

  mainFileNotFoundListner(){
    this.mainFileNotFoundNotifier = this.projectPlayerService.listenMainFileNotFound().subscribe((res) => {
      this.toastService.error(res.message);
      this.mainFileNotFoundNotifier.unsubscribe();
    });
  }

  ngOnDestroy() {
    this.unSubscribe.next();
    this.unSubscribe.unsubscribe();
    this.mainFileNotFoundNotifier.unsubscribe();
  }

}
