import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProjectRoutingModule } from './project-routing.module';
import { ProjectComponent } from './project.component';
import { SetupComponent } from './setup/setup.component';
import { ConsoleComponent } from './console/console.component';
import { RubricsComponent } from './rubrics/rubrics.component';
import { TryComponent } from './try/try.component';
import { NG_EVENT_PLUGINS } from '@tinkoff/ng-event-plugins';
import { CkEditorSharedModule } from "../../shared/modules/ck-editor/ck-editor.module";
import { PlayerModule } from 'src/app/shared/modules/player/player.module';
import { WrapperModule } from 'src/app/shared/modules/wrapper/wrapper.module';
import { ProjectService } from './project.service';
import { OverlaySkeletonComponent } from 'src/app/shared/components/skeleton-ui/overlay-skeleton/overlay-skeleton.component';

@NgModule({
  declarations: [
    ProjectComponent,
    SetupComponent,
    ConsoleComponent,
    RubricsComponent,
    TryComponent
  ],
  imports: [
    CommonModule,
    OverlaySkeletonComponent,
    ProjectRoutingModule,
    CkEditorSharedModule,
    WrapperModule,
    PlayerModule
  ],
  providers: [NG_EVENT_PLUGINS, ProjectService]
})
export class ProjectModule { }
