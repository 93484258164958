/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RubricsPayload, SetupInterface } from 'src/app/models/projectInterface';
import { ProjectService } from '../project.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectPlayerModes } from 'src/app/enums/PlayerModes';
import { ProjectPlayerService } from 'src/app/shared/modules/player/project-player.service';
import { ExerciseTabModes } from 'src/app/exercise/exercise-builder/exercise-builder.component';
import { AddProjects } from 'src/app/models/exerciseInterface';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-rubrics',
  templateUrl: './rubrics.component.html',
  styleUrls: ['./rubrics.component.scss']
})
export class RubricsComponent {
  rubricForm !: FormGroup;
  sum = 0;
  isUserWhiteBoxInputDisable = true;
  isEvaluatorWhiteBoxInputDisable = true;
  isUserBlackBoxInputDisable = true;
  isEvaluatorBlackBoxInputDisable = true;
  subjectToUnsubscribe = new Subject;
  exerciseIdTry: string | undefined;
  constructor(private _fb: FormBuilder, public projectService: ProjectService, private projectPlayerService: ProjectPlayerService, private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.initializeForm();
    if (this.projectService.projectMode === ProjectPlayerModes.EDIT &&
      this.projectService.projectDetails &&
      this.projectService.projectDetails.rubricWeightage) {
      this.patchRubricForm();
    }
    this.whiteBoxEnable();
    this.blackBoxEnable();
  }

  blackBoxEnable() {
    this.isUserBlackBoxInputDisable = this.projectService.isUserBlackBoxInputDisable;
    this.isEvaluatorBlackBoxInputDisable = this.projectService.isEvaluatorBlackBoxInputDisable;
    if (this.isUserBlackBoxInputDisable) {
      this.rubricForm.controls['userBlackBox'].setValue(0);
    }
    if (this.isEvaluatorBlackBoxInputDisable) {
      this.rubricForm.controls['evaluatorBlackBox'].setValue(0);
    }
  }

  whiteBoxEnable() {
    if (this.projectPlayerService.projectDetails) {
      if (this.projectPlayerService.projectDetails.userWhiteBoxEnabled !== undefined) {
        this.isUserWhiteBoxInputDisable = !this.projectPlayerService.projectDetails.userWhiteBoxEnabled;
      }
      if (this.projectPlayerService.projectDetails.evalWhiteBoxEnabled !== undefined) {
        this.isEvaluatorWhiteBoxInputDisable = !this.projectPlayerService.projectDetails.evalWhiteBoxEnabled;
      }
    }
    else if (this.projectService.projectDetails) {
      this.isEvaluatorWhiteBoxInputDisable = this.projectService.isEvaluatorWhiteBoxInputDisable;
      this.isUserWhiteBoxInputDisable = this.projectService.isUserWhiteBoxInputDisable;
    }
    if (this.isUserWhiteBoxInputDisable) {
      this.rubricForm.controls['userWhiteBox'].patchValue(0);
    }
    if (this.isEvaluatorWhiteBoxInputDisable) {
      this.rubricForm.controls['evaluatorWhiteBox'].patchValue(0);
    }
  }

  initializeForm() {
    this.rubricForm = this._fb.group({
      compilation: new FormControl(0, [Validators.min(0), Validators.max(100),]),
      userBlackBox: new FormControl(0, [Validators.min(0), Validators.max(100),]),
      evaluatorBlackBox: new FormControl(0, [Validators.min(0), Validators.max(100)]),
      userWhiteBox: new FormControl(0, [Validators.min(0), Validators.max(100)]),
      evaluatorWhiteBox: new FormControl(0, [Validators.min(0), Validators.max(100)])
    });
    this.rubricForm.setValidators(this.sumValidator());
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public sumValidator(): any {
    return (group: FormGroup) => {
      this.sum = 0;
      Object.keys(group.controls).forEach(key => {
        this.sum += group.controls[key].value;
      });
      let errors = null;
      this.sum !== 100 ? errors = { invalidSum: true } : '';
      group.controls['compilation'].setErrors(errors);
    };
  }

  patchRubricForm() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const data = this.projectService.projectDetails.rubricWeightage!;
    this.rubricForm.patchValue({
      compilation: data.compilation,
      userBlackBox: data.userBlackBox,
      evaluatorBlackBox: data.evalBlackBox,
      userWhiteBox: data.userWhiteBox,
      evaluatorWhiteBox: data.evalWhiteBox
    });
  }

  cancelForm(): void {
    const obj: SetupInterface = {
      projectId: this.projectService.projectDetails.projectId,
      title: this.projectService.projectDetails.title!,
      difficultyLevel: this.projectService.projectDetails.difficultyLevel!,
      archetypes: this.projectService.projectDetails.archetypes!,
      problemStatement: this.projectService.projectDetails.problemStatement!,
      instructions: this.projectService.projectDetails.instructions!,
      example: this.projectService.projectDetails.example!,
      isDraft: true
    };
    this.projectService.updateSetupForm(obj)
      .pipe(takeUntil(this.subjectToUnsubscribe))
      .subscribe(() => {
        this.saveRubricOnCancel();
      });

  }

  // eslint-disable-next-line max-lines-per-function
  saveRubrics() {
    //setup failure listener
    this.rubricsSaveFailure();
    const exerciseId = this.activatedRoute.snapshot.queryParams['exerciseId'];
    const exercisesLinked = this.projectService.projectDetails.linkedExercises?.length || 0;
    if (exerciseId && (this.projectService.projectMode === ProjectPlayerModes.EDIT && exercisesLinked > 0)) {
      this.exerciseIdTry = exerciseId;
    }
    const payload: RubricsPayload = {
      rubricWeightage: {
        "compilation": this.rubricForm.controls['compilation'].value,
        "userBlackBox": this.rubricForm.controls['userBlackBox'].value,
        "evalBlackBox": this.rubricForm.controls['evaluatorBlackBox'].value,
        "userWhiteBox": this.rubricForm.controls['userWhiteBox'].value,
        "evalWhiteBox": this.rubricForm.controls['evaluatorWhiteBox'].value
      },
      ...(this.exerciseIdTry && { exerciseId: this.exerciseIdTry }),
    };
    this.projectService.saveRubrics(payload)
      .pipe(takeUntil(this.subjectToUnsubscribe))
      .subscribe(() => {
        this.storeRubricFormToProjectDetails(payload);
        if (this.activatedRoute.snapshot.queryParams['contentRoot'] === 'exercise_projects') {
          //after edit redirect to exercise-projects screen
          this.router.navigate(['/exercise/' + exerciseId + '/manipulate-exercise'], { queryParamsHandling: 'merge', queryParams: { exerciseManipulationTab: ExerciseTabModes.EXERCISE_PROJECTS, contentRoot: null, exerciseId: null, projectManipulationTab: null } });
        }
        else if (this.activatedRoute.snapshot.queryParams['contentRoot'] === 'view_exercise') {
          //after edit redirect to view exercise details
          this.router.navigate(['/exercise/' + exerciseId + '/view-exercise'], { queryParamsHandling: 'merge', queryParams: { contentRoot: null, exerciseId: null, projectManipulationTab: null } });
        }
        else if (this.activatedRoute.snapshot.queryParams['contentRoot'] === 'try_project') {
          // after edit redirect to tryproject screen
          this.router.navigate(['/exercise/' + this.projectService.projectDetails.projectId + '/try-project'], { queryParams: { contentRoot: null, projectManipulationTab: null }, queryParamsHandling: 'merge' });
        }
        else if (this.activatedRoute.snapshot.queryParams['exerciseId'] && !this.activatedRoute.snapshot.queryParams['contentRoot']) {
          const exerciseId = this.activatedRoute.snapshot.queryParams['exerciseId'];
          //store the project in the exercise
          const addProjectPayload: AddProjects = {
            exerciseId,
            projects: [
              {
                projectId: this.projectService.projectId,
                maxMarks: 20
              }
            ],
          };
          this.projectService.addProjectToExercise(addProjectPayload).subscribe(() => {
            this.router.navigate(['/exercise/' + exerciseId + '/manipulate-exercise'], { queryParamsHandling: 'merge', queryParams: { exerciseManipulationTab: ExerciseTabModes.EXERCISE_PROJECTS, exerciseId: null, projectManipulationTab: null } });
          });
        }
        else if (this.activatedRoute.snapshot.queryParams['tryProjectFrom'] === 'project_bank') {
          this.router.navigate(['./' + this.projectService.projectDetails.projectId + '/try-project'], { queryParamsHandling: 'merge', queryParams: { projectManipulationTab: null } });
        }
        else {
          //On successful save redirect to project bank.
          this.router.navigate(['/project-bank'], { queryParamsHandling: 'merge', queryParams: { projectManipulationTab: null } });
        }
      });
  }

  saveRubricOnCancel() {
    //setup failure listener
    this.rubricsSaveFailure();
    const payload: RubricsPayload = {
      rubricWeightage: {
        "compilation": this.rubricForm.controls['compilation'].value,
        "userBlackBox": this.rubricForm.controls['userBlackBox'].value,
        "evalBlackBox": this.rubricForm.controls['evaluatorBlackBox'].value,
        "userWhiteBox": this.rubricForm.controls['userWhiteBox'].value,
        "evalWhiteBox": this.rubricForm.controls['evaluatorWhiteBox'].value
      },
    };
    //On successful save redirect to project bank.
    this.projectService.saveRubrics(payload)
      .pipe(takeUntil(this.subjectToUnsubscribe))
      .subscribe(() => {
        this.storeRubricFormToProjectDetails(payload);
        // if exerciseId is there and we click cancel then it should save as draft but not add it to exercise project as it is in draft state
        if (this.activatedRoute.snapshot.queryParams['exerciseId']) {
          const exerciseId = this.activatedRoute.snapshot.queryParams['exerciseId'];
          this.router.navigate(['/exercise/' + exerciseId + '/manipulate-exercise'], { queryParamsHandling: 'merge', queryParams: { exerciseManipulationTab: ExerciseTabModes.EXERCISE_PROJECTS, exerciseId: null, projectManipulationTab: null } });
        }
        else {
          this.router.navigate(['/project-bank'], { queryParamsHandling: 'merge' });
        }
      });
  }

  storeRubricFormToProjectDetails(rubricPayload: RubricsPayload) {
    this.projectService.projectDetails.rubricWeightage = {
      compilation: rubricPayload.rubricWeightage.compilation,
      userBlackBox: rubricPayload.rubricWeightage.userBlackBox,
      evalBlackBox: rubricPayload.rubricWeightage.evalBlackBox,
      userWhiteBox: rubricPayload.rubricWeightage.userWhiteBox,
      evalWhiteBox: rubricPayload.rubricWeightage.evalWhiteBox,
    };

  }

  rubricsSaveFailure() {
    this.projectService.rubricsSaveFailure()
      .pipe(takeUntil(this.subjectToUnsubscribe))
      .subscribe(() => {
        //retry saving rubrics after 2000 milliseconds
        setTimeout(() => {
          this.saveRubrics();
        }, 2000);
      });
  }

  ngOnDestroy() {
    this.subjectToUnsubscribe.next(true);
    this.subjectToUnsubscribe.unsubscribe();
  }

}
