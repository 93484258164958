import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectPlayerComponent } from '../../components/project-player/project-player.component';
import { TestCasesComponent } from '../../components/project-player/test-cases/test-cases.component';
import { FileEditorComponent } from '../../components/project-player/file-editor/file-editor.component';
import { FileExplorerComponent } from '../../components/project-player/file-explorer/file-explorer.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProjectPlayerService } from './project-player.service';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { BlackBoxComponent } from '../../components/project-player/black-box/black-box.component';
import { CustomInputComponent } from '../../components/project-player/custom-input/custom-input.component';
import { WrapperModule } from '../wrapper/wrapper.module';
import { PreSubmissionComponent } from '../../components/project-player/pre-submission/pre-submission.component';
import { ExercisePlayerComponent } from '../../components/exercise-player/exercise-player.component';
import { ExercisePlayerService } from './exercise-player.service';
import { EvaluationPlayerComponent } from '../../components/evaluation-player/evaluation-player.component';
import { EvaluationPlayerService } from './evaluation-player.service';
import { SelectionModelComponent } from '../../components/selection-model/selection-model.component';
import { ExercisePlayerModalComponent } from '../../components/exercise-player-modal/exercise-player-modal.component';
import { ImageZoomComponent } from '../../components/project-player/image-zoom/image-zoom.component';
import { ProjectDetailsComponent } from '../../components/project-player/project-details/project-details.component';
import { SqlFileUploaderComponent } from '../../components/project-player/sql-file-uploader/sql-file-uploader.component';
import { GetkeysPipeModule } from "../../../pipes/getkeys.pipe";
import { IsElementArrayPipeModule } from 'src/app/pipes/is-element-array.pipe';
import { DirectoryCreationComponent } from '../../components/directory-creation/directory-creation.component';
import { OverlaySkeletonComponent } from '../../components/skeleton-ui/overlay-skeleton/overlay-skeleton.component';

@NgModule({
  declarations: [ProjectPlayerComponent, FileExplorerComponent, TestCasesComponent, FileEditorComponent, BlackBoxComponent, CustomInputComponent, PreSubmissionComponent, ExercisePlayerComponent, EvaluationPlayerComponent, SelectionModelComponent, ExercisePlayerModalComponent, ImageZoomComponent, ProjectDetailsComponent, SqlFileUploaderComponent, DirectoryCreationComponent],
  exports: [ProjectPlayerComponent, ExercisePlayerComponent, EvaluationPlayerComponent, ExercisePlayerModalComponent],
  providers: [ProjectPlayerService, ExercisePlayerService, EvaluationPlayerService],
  imports: [
    CommonModule,
    NgbModule,
    OverlaySkeletonComponent,
    WrapperModule,
    MonacoEditorModule.forRoot(),
    GetkeysPipeModule,
    IsElementArrayPipeModule
  ]
})
export class PlayerModule { }
