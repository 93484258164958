export interface ProjectListCardItem {
  title: string,
  projectId: string,
  userName: string,
  difficultyLevel: DifficultyLevel,
  archetypes: Archetype[],
  isDraft: boolean,
  selected?: boolean,
  maxMarks?: number,
  createdAt?: string,
  updatedAt?: string,
  countOfExercises?: number,
}

export enum DifficultyLevel {
  EASY = 'Easy',
  MEDIUM = 'Medium',
  DIFFICULT = 'Difficult'
}
export interface Archetype {
  archetypeId: string,
  language: string,
  label: string,
  targetFileName: string,
  sourceFileName: string,
  dbName?: string,
  password?: string
}

export interface Configuration {
  level: string[],
  languages: { language: string, versions: { langId: string, label: string, archetypes: { targetFileName: string, sourceFileName: string, label: string, archetypeId: string }[] }[] }[],
  // author: string[],
  status?: string[],
  exerciseStatus?: string[],
}

