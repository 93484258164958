import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExerciseTabModes } from '../exercise-builder.component';
import { ExerciseBuilderService } from '../exercise-builder.service';
import { ExerciseBuilderModes } from 'src/app/enums/exerciseBuilderModes';
import { ProjectListCardItem } from 'src/app/models/project-list-card';
import { SkeletonLoaderType } from 'src/app/enums/skeletonui';
import { ProjectListCardModes } from 'src/app/enums/projectListCardMode';
import { ExerciseDetails, saveAsDraft } from 'src/app/models/exerciseInterface';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MarksModalComponent, MarksMode } from '../../modals/marks-modal/marks-modal.component';
import { Subject, takeUntil } from 'rxjs';
import { ExerciseStatus } from 'src/app/enums/exerciseForm';

interface ProjectListCardItemVisibility extends ProjectListCardItem {
  visible: boolean;
}

@Component({
  selector: 'app-exercise-projects',
  templateUrl: './exercise-projects.component.html',
  styleUrls: ['./exercise-projects.component.scss']
})
export class ExerciseProjectsComponent {
  subjectToUnsubscribe = new Subject;
  projectListInsideExercise!: ProjectListCardItemVisibility[];
  SkeletonLoaderType = SkeletonLoaderType;
  ProjectListCardModes = ProjectListCardModes;
  exerciseId!: string;
  totalMarks = 0;
  searchPattern = '';
  modalRef!: NgbModalRef;
  exerciseStatus = ExerciseStatus;
  status = ExerciseStatus.NOT_ATTACHED;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private exerciseBuilderService: ExerciseBuilderService, private NgbModal: NgbModal) { }
  ngOnInit() {
    this.checkEditModeAndStoreProjectList();
  }
  checkEditModeAndStoreProjectList() {
    if (this.exerciseBuilderService.exerciseBuilderMode === ExerciseBuilderModes.CREATE && !this.exerciseBuilderService.exerciseId) {
      this.router.navigate([], { queryParams: { exerciseManipulationTab: 'exercise-setup' }, queryParamsHandling: 'merge', skipLocationChange: true });
    }
    else {
      this.exerciseBuilderService.listenToExerciseDetailsState()
        .pipe(takeUntil(this.subjectToUnsubscribe))
        .subscribe(() => {
          this.projectListInsideExercise = this.exerciseBuilderService.exerciseDetails.projects.map((projectItem: ProjectListCardItem) => {
            const newProjectItem = Object.assign(projectItem, { visible: true });
            return newProjectItem;
          }
          );
          this.calculateTotalMarks();
          //console.log(this.exerciseBuilderService.exerciseDetails);
        });
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.exerciseId = this.activatedRoute.snapshot.paramMap.get('exerciseId')! || this.exerciseBuilderService.exerciseId;
    this.storeExerciseDetails();
  }

  calculateTotalMarks() {
    this.totalMarks = 0;
    this.projectListInsideExercise.map((projectlistItem: ProjectListCardItem) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.totalMarks += projectlistItem.maxMarks!;
    });
  }

  handleSearch() {
    const regex = new RegExp(this.searchPattern, 'i'); // 'i' flag for case-insensitive matching
    this.projectListInsideExercise.forEach((projectItem) => {
      const matchesPattern = regex.test(projectItem.title);
      projectItem.visible = matchesPattern;
    });
  }

  async storeExerciseDetails() {
    const getExercisePaylod = {
      exerciseId: this.exerciseId
    };
    this.exerciseBuilderService.getExerciseDetails(getExercisePaylod)
      .pipe(takeUntil(this.subjectToUnsubscribe))
      .subscribe((data: ExerciseDetails) => {
        this.exerciseBuilderService.exerciseDetails = data;
        this.exerciseBuilderService.publishExerciseDetailsState();
        this.status = data.status;
      });
  }

  createNewProject() {
    this.router.navigate(['../../../project-bank/create/manipulate-project'], { relativeTo: this.activatedRoute, queryParams: { exerciseId: this.exerciseId }, queryParamsHandling: 'merge' });
  }

  addFromExistingProjects() {
    this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: { exerciseId: this.exerciseBuilderService.exerciseId, exerciseManipulationTab: ExerciseTabModes.PROJECT_SELECTION }, queryParamsHandling: 'merge' });
    // this.router.navigate(['./select-project'], {relativeTo: this.activatedRoute, queryParams: {exerciseId: 'exercise123'} , queryParamsHandling: 'merge'});
  }

  removeProject(projectId: string) {
    this.projectListInsideExercise = this.projectListInsideExercise.filter(project => project.projectId !== projectId);
    this.calculateTotalMarks();
  }

  editMarksModel() {
    this.modalRef = this.NgbModal.open(MarksModalComponent, { windowClass: 'createNewExercise-modal' });
    this.modalRef.componentInstance.projectListInsideExercise = this.projectListInsideExercise;
    this.modalRef.componentInstance.exerciseId = this.exerciseId;
    this.modalRef.componentInstance.marksMode = MarksMode.EDIT_MARKS;
    this.modalRef.componentInstance.closedStatus.subscribe(() => {
      this.projectListInsideExercise = this.modalRef.componentInstance.projectListInsideExercise;
      this.totalMarks = this.modalRef.componentInstance.totalMarks;
      this.modalRef.close();
      //this.checkEditModeAndStoreProjectList();
    });
  }

  editMarks() {
    //open modal
    this.editMarksModel();
  }

  saveReorder() {
    const projectIds = this.projectListInsideExercise.map(project => project.projectId);
    const reorderPayload = {
      exerciseId: this.exerciseId,
      projectIds: projectIds
    };
    this.exerciseBuilderService.reorderProjects(reorderPayload)
      .pipe(takeUntil(this.subjectToUnsubscribe))
      .subscribe(() => {
        if (this.exerciseBuilderService.redirectBack) {
          this.redirectToLumen();
          return;
        }
        this.router.navigate(['../../../exercise']);
      });
  }

  saveAsDraft() {
    if (this.exerciseBuilderService.exerciseDetails.status === ExerciseStatus.ATTACHED) { // if status is already attached there should be any override on status that is why we are mare putting this check so that it will change to draft
      if (this.exerciseBuilderService.redirectBack) {
        this.redirectToLumen();
        return;
      }
      this.router.navigate(['../../../exercise']);
    }
    else {
      const updatedPayload: saveAsDraft = {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        exerciseId: this.activatedRoute.snapshot.paramMap.get('exerciseId')!,
        status: ExerciseStatus.DRAFT
      };
      this.exerciseBuilderService.saveExerciseAsDraft(updatedPayload)
        .pipe(takeUntil(this.subjectToUnsubscribe))
        .subscribe(() => {
          if (this.exerciseBuilderService.redirectBack) {
            this.redirectToLumen();
            return;
          }
          this.router.navigate(['../../../exercise']);
        });
    }
  }

  redirectToLumen() {
    let url = `${this.exerciseBuilderService.redirectBack}`;
    if (!url.includes('exerciseId')) {
      url = url + `&exerciseId=${this.exerciseId}`;
    }
    location.replace(url);
  }

  ngOnDestroy() {
    this.subjectToUnsubscribe.next(true);
    this.subjectToUnsubscribe.unsubscribe();
  }
}
