import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-overlay-skeleton',
  standalone: true,
  templateUrl: './overlay-skeleton.component.html',
  styleUrls: ['./overlay-skeleton.component.scss']
})
export class OverlaySkeletonComponent {
  @Input() dynamicTag = 'Loading ...';

}
