import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DialogTypes } from 'src/app/enums/dialog';
import { Dialog } from 'src/app/models/dialog';
import { IClonnedExercise, deleteExercise } from 'src/app/models/exerciseInterface';
import { ExerciseService } from '../../../exercise/exercise.service';
import { DialogService } from 'src/app/services/dialog.service';
import { CommonUtils, timeOutput } from 'src/app/utils/common-utils';
import { ExerciseListCardModes } from 'src/app/enums/exerciseListCardModes';

@Component({
  selector: 'app-exercise-list-card',
  templateUrl: './exercise-list-card.component.html',
  styleUrls: ['./exercise-list-card.component.scss']
})
export class ExerciseListCardComponent {
  exerciseStatus!: string;
  formattedArchetypes!: string[];
  ExerciseListCardModes = ExerciseListCardModes;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() exerciseListCardItem!: any;
  @Input() displayMode!: ExerciseListCardModes;
  formattedTime!: timeOutput;
  @Output() deletedExercise = new EventEmitter;
  @Output() clonedExerciseEmitter = new EventEmitter;
  displayTime!: string;
  totalProjects!: number;
  filteredValues: string[] = [];
  remainingValuesCount!: number;

  constructor(private translationService: TranslateService, private NgbModal: NgbModal, private exerciseService: ExerciseService, private dialogService: DialogService) { }
  modelRef!: NgbModalRef;

  ngOnInit() {
    this.filteredLangAndArchtypes();
    const commonUtils = new CommonUtils();
    this.exerciseStatus = commonUtils.getExerciseStatus(
      this.exerciseListCardItem.status,
      new Date(this.exerciseListCardItem.startDate),
      new Date(this.exerciseListCardItem.endDate), this.exerciseListCardItem.projects);
    this.formattedArchetypes = commonUtils.formatUniqueArchetypes(this.exerciseListCardItem.projects);
    const time = this.exerciseListCardItem.duration || 0;
    this.formattedTime = commonUtils.getTimeInHourAndMinute(time);
    this.displayTime = commonUtils.displayTimeFormat(this.formattedTime);
    this.totalProjects = this.exerciseListCardItem.projects.length;
  }

  async deleteModal() {
    let message!: string;
    let note!: string;
    const obj: deleteExercise = {
      exerciseId: this.exerciseListCardItem.exerciseId
    };
    this.translationService.get('exercise.deleteExercise', { type: DialogTypes.ERROR }).subscribe((translation) => {
      message = translation;
    }).unsubscribe();
    this.translationService.get('confirmModal.noteExercise', { itemName: this.exerciseListCardItem.title, projectsCount: this.totalProjects }).subscribe((translation) => {
      note = translation;
    }).unsubscribe();
    const dialog: Dialog = { title: { translationKey: message }, note: { translationKey: note } };
    const confirmation = await this.dialogService.showConfirmDialog(dialog);
    if (confirmation) {
      this.exerciseService.deleteExercise(obj).subscribe(() => {
        this.deletedExercise.emit(this.exerciseListCardItem.exerciseId);
      });
    }
  }

  cloneExercise() {
    const obj: deleteExercise = {
      exerciseId: this.exerciseListCardItem.exerciseId
    };
    const cloneUnsubscribe = this.exerciseService.cloneExercise(obj).subscribe((cloneExercisedata: IClonnedExercise) => {
      this.clonedExerciseEmitter.emit(cloneExercisedata.exercise);
      cloneUnsubscribe.unsubscribe();
    });
  }
  filteredLangAndArchtypes() {
    const commonUtils = new CommonUtils();
    this.formattedArchetypes = commonUtils.formatUniqueArchetypes(this.exerciseListCardItem.projects);
    const valuesArray = this.formattedArchetypes[0].split(' , ');
    const finalArr = valuesArray.slice(0, 3);
    this.filteredValues = [finalArr.join('  ,  ')];
    this.remainingValuesCount = valuesArray.length - finalArr.length;
  }

}
