import { Injectable } from '@angular/core';
import { AttemptEventKey } from 'src/app/enums/attemptEventKeys';
import { ProjectEventsKey } from 'src/app/enums/projectEventsKey';
import { AddContent, GetDirectory, GetProjectFileContent, IAutoSaveFile, IDeleteDirectory, IRenameDirectory, Tree, compileAndRun } from 'src/app/models/projectInterface';
import { SocketService } from 'src/app/services/socket.service';
import { ProjectPlayerService } from './project-player.service';
import { Subject, Subscription, firstValueFrom } from 'rxjs';
import { IAttemptData, IAttemptProjectDetails, IMarkedForReview, ISubmitProject } from 'src/app/models/attemptInterface';
import { LayoutModes } from 'src/app/enums/layoutModes';
import { StorageService } from 'src/app/services/storage.service';
import { DialogService } from 'src/app/services/dialog.service';
import { CommonService } from 'src/app/services/common.service';

@Injectable()
export class ExercisePlayerService extends ProjectPlayerService {
  interval!: Subscription;
  timeLeft = new Subject<number>();
  public override attemptId!: string;
  public candidateId!: string;
  override exerciseId!: string;
  public override projectDetails!: IAttemptProjectDetails;
  public override attemptData!: IAttemptData;
  private projectChange = new Subject<IAttemptProjectDetails>();
  public override isMarkedForReviewIDs = new Map();
  public override answeredStatusIds = new Map();
  public override projectsAnswered = 0;
  public override projectReviewed = 0;
  public override lastSavedStatusIds = new Map();
  public override treeDataSolution!: Tree;
  public override viewSolutionLayout = LayoutModes.NONE;
  public override lastSavedArchetype = new Map();
  public override isReAttempt = false;
  public override redirectBack!: string;
  public override projectArchetypesMap: Map<string, string[]> = new Map();
  private projectDetailsDisplay = new Subject<IAttemptProjectDetails>();
  constructor(protected override socketService: SocketService, protected override storageService: StorageService, protected override dialogService: DialogService, protected override commonService: CommonService) {
    super(socketService, storageService, dialogService, commonService);
  }

  startOrResumeAttempt() {
    const payload = {
      //exerciseId: crypto.randomUUID().replaceAll('-', ''),
      exerciseId: this.exerciseId,
      ...(this.attemptId && { attemptId: this.attemptId }),
      ...(this.candidateId && { rpCandidateId: this.candidateId })
    };
    return this.socketService.getDataFromSocket(AttemptEventKey.START_RESUME_ATTEMPT, AttemptEventKey.ATTEMPT_METADATA_CREATED, payload, true);
  }

  override listenValidationError() {
    return this.socketService.listenToEventWithoutHistory(AttemptEventKey.VALIDATION_ERROR);
  }

  attemptCreated() {
    return this.socketService.listenToEventWithoutHistory(AttemptEventKey.ATTEMPT_CREATED);
  }

  resumeAttempt() {
    return this.socketService.listenToEventWithoutHistory(AttemptEventKey.RESUME_ATTEMPT);
  }

  override getDirectoryDetails(obj: GetDirectory) {
    obj.attemptId = this.attemptId;
    obj.exerciseId = this.exerciseId;
    return this.socketService.getDataFromSocket(ProjectEventsKey.GET_DIRECTORY_DETAILS, ProjectEventsKey.DIRECTORY_DETAILS_SUCCESS, obj, true);
  }

  override requestProjectFileContent(obj: GetProjectFileContent) {
    obj.attemptId = this.attemptId;
    obj.exerciseId = this.exerciseId;
    return this.socketService.getDataFromSocket(ProjectEventsKey.GET_FILE_CONTENT, ProjectEventsKey.FILE_CONTENT_SUCCESS, obj, true);
  }

  override listenProjectChange() {
    return this.projectChange.asObservable();
  }

  override triggerProjectChange(obj: IAttemptProjectDetails) {
    this.projectChange.next(obj);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  override triggerToDisplayProjectDetails(obj: IAttemptProjectDetails) {
    this.projectDetailsDisplay.next(obj);
  }

  override listenToDisplayProjectDetails() {
    return this.projectDetailsDisplay.asObservable();
  }

  override compileAndRun(payload: compileAndRun) {
    payload.attemptId = this.attemptId;
    payload.exerciseId = this.exerciseId;
    return this.socketService.getDataFromSocket(ProjectEventsKey.RUN, ProjectEventsKey.FETCHING_META_DATA, payload, true);
  }

  override async saveAllFiles(): Promise<boolean> {
    const payload: IAutoSaveFile = {
      projectId: this.projectId,
      archetypeId: this.selectedArchetype,
      attemptId: this.attemptId,
      exerciseId: this.exerciseId,
      files: []
    };
    payload.files = this.tabs.map(item => {
      return {
        content: item.code,
        path: item.path
      };
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const executor = (resolve: any, reject: (error: boolean) => void) => {
      firstValueFrom(this.SaveContentOfFile(payload)).then((data) => {
        this.setLastSavedStatus(this.projectId, data.files[0].lastSavedOn);
        resolve(true);
      });
      firstValueFrom(this.socketService.listenToEventWithoutHistory(ProjectEventsKey.SAVE_FAILED)).then(() => { reject(false); });
      firstValueFrom(this.socketService.listenToEventWithoutHistory(AttemptEventKey.VALIDATION_ERROR)).then(() => { reject(false); });
    };
    const saveFilePromise = new Promise<boolean>(executor);
    return saveFilePromise;
  }

  override addFileOrDirectory(payload: AddContent) {
    payload.attemptId = this.attemptId;
    payload.exerciseId = this.exerciseId;
    return this.socketService.getDataFromSocket(ProjectEventsKey.ADD_FILE_DIRECTORY, ProjectEventsKey.FILE_DIRECTORY_ADDED, payload, true);
  }

  override DeleteFileFromTree(obj: IDeleteDirectory) {
    obj.attemptId = this.attemptId;
    obj.exerciseId = this.exerciseId;
    return this.socketService.getDataFromSocket(ProjectEventsKey.REMOVE_FILE_DIRECTORY, ProjectEventsKey.FILE_DIRECTORY_REMOVED, obj, true);
  }

  override renameFileFromTree(obj: IRenameDirectory) {
    obj.attemptId = this.attemptId;
    obj.exerciseId = this.exerciseId;
    return this.socketService.getDataFromSocket(ProjectEventsKey.RENAME_FILE_DIRECTORY, ProjectEventsKey.FILE_DIRECTORY_RENAMED, obj, true);
  }

  override submitExercise() {
    const payload = {
      attemptId: this.attemptId,
    };
    return this.socketService.sendMessage(AttemptEventKey.SUBMIT_EXERCISE, payload);
  }

  override listenSubmitExercise() {
    return this.socketService.listenToEventWithoutHistory(AttemptEventKey.EXERCISE_SUBMITTED);
  }

  override setMarkedForReview(projectId: string, status: boolean) {
    this.isMarkedForReviewIDs.set(projectId, status);
  }

  override getMarkedForReview(projectId: string): boolean {
    return this.isMarkedForReviewIDs.get(projectId);

  }
  override markedForReviewCount(): number {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const filtered = new Map(Array.from(this.isMarkedForReviewIDs).filter(([key, value]) => { return value; }),);
    this.projectReviewed = filtered.size;
    return this.projectReviewed;
  }

  override setAnswerStatus(projectId: string, status: boolean) {
    this.answeredStatusIds.set(projectId, status);
  }

  override getAnswerStatus(projectId: string): boolean {
    return this.answeredStatusIds.get(projectId);
  }

  override projectMarkedForReview(payload: IMarkedForReview) {
    return this.socketService.getDataFromSocket(AttemptEventKey.IS_PROJECT_MARKED_FOR_REVIEW, AttemptEventKey.IS_PROJECT_MARKED_FOR_REVIEW_SUCCESS, payload, true);
  }
  override AnswerStatusCount(): number {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const filtered = new Map(Array.from(this.answeredStatusIds).filter(([key, value]) => { return value; }),);
    this.projectsAnswered = filtered.size;
    return this.projectsAnswered;
  }

  override updateProjectStatus(status = 'inProgress') {
    const payload = {
      attemptId: this.attemptId,
      projectId: this.projectId,
      status: status
    };
    return this.socketService.getDataFromSocket(AttemptEventKey.UPDATE_PROJECT_STATUS, AttemptEventKey.PROJECT_STATUS_UPDATED, payload, true);
  }

  override async autoSave(): Promise<boolean> {
    this.updateTimeStamp();
    const payload: IAutoSaveFile = {
      projectId: this.projectId,
      archetypeId: this.selectedArchetype,
      attemptId: this.attemptId,
      exerciseId: this.exerciseId,
      files: [],
      callTimeSpentEvent: this.attemptData.timeLimit === 0 ? false : true
    };
    if (this.getSubmitState()) {
      payload.files = this.tabs.map(item => {
        return {
          content: item.code,
          path: item.path
        };
      });
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const executor = (resolve: any, reject: (error: boolean) => void) => {
      firstValueFrom(this.autoSaveContentOfFile(payload)).then((data) => {
        this.setLastSavedStatus(this.projectId, data.files[0].lastSavedOn);
        resolve(true);
      });
      firstValueFrom(this.socketService.listenToEventWithoutHistory(ProjectEventsKey.SAVE_FAILED)).then(() => { reject(false); });
      firstValueFrom(this.socketService.listenToEventWithoutHistory(AttemptEventKey.VALIDATION_ERROR)).then(() => { reject(false); });
    };
    const saveFilePromise = new Promise<boolean>(executor);
    return saveFilePromise;
  }

  retrieveUpdatedTimeStamp() {
    return this.socketService.listenToEventWithoutHistory(AttemptEventKey.TIME_SPENT_UPDATED);
  }

  updateTimeStamp() {
    const retrieveUpdatedTimeStampUnSubscription = this.retrieveUpdatedTimeStamp().subscribe((data: { currentTimeStamp: Date, timeLeft: number }) => {
      this.timeLimitInSeconds = data.timeLeft;
      retrieveUpdatedTimeStampUnSubscription.unsubscribe();
    });
  }

  autoSaveContentOfFile(payload: IAutoSaveFile) {
    return this.socketService.getDataFromSocket(AttemptEventKey.AUTO_SAVE, AttemptEventKey.AUTO_SAVE_OK, payload, true);
  }

  override submitProject(payload: ISubmitProject) {
    return this.socketService.getDataFromSocket(AttemptEventKey.SUBMIT_PROJECT, AttemptEventKey.PROJECT_SUBMITTED, payload, true);
  }

  override setLastSavedStatus(projectId: string, time: string) {
    this.lastSavedStatusIds.set(projectId, time);
  }

  override getLastSavedStatus(projectId: string): string {
    return this.lastSavedStatusIds.get(projectId);
  }

  override setLastSavedArchetype(projectId: string, archetype: string) {
    this.lastSavedArchetype.set(projectId, archetype);
  }

  override getLastSavedArchetype(projectId: string): string {
    return this.lastSavedArchetype.get(projectId);
  }

  override updateProjectArchetypesMap(projectId: string, archetypeId: string) {
    if (this.projectArchetypesMap.has(projectId)) {
      // Project already exists in the Map, update the array of archetype IDs
      const archetypesArray = this.projectArchetypesMap.get(projectId) || [];
      if (archetypesArray.includes(archetypeId)) {
        return;
      }
      archetypesArray.push(archetypeId);
      this.projectArchetypesMap.set(projectId, archetypesArray);
    } else {
      // Project doesn't exist in the Map, create a new entry
      this.projectArchetypesMap.set(projectId, [archetypeId]);
    }
  }

  override getExerciseSubmitStatus(decodedUrl: string) {
    const queryString = decodedUrl.split('?')[1];
    const queryParams = new URLSearchParams(queryString);
    return queryParams.get('isSubmitted');
  }

  override setExerciseSubmitStatus(decodedUrl: string, status: boolean) {
    const [queryUrl, queryString] = decodedUrl.split('?');
    const queryParams = new URLSearchParams(decodedUrl);
    queryParams.set('isSubmitted', status ? 'true' : 'false');
    return queryUrl + '?' + queryString;
  }

  override setUrl(status: boolean) {
    const decodedUrl = decodeURIComponent(this.redirectBack);
    const isSubmitted = this.getExerciseSubmitStatus(decodedUrl);
    if (isSubmitted) {
      const urlWithSubmittedStatus = this.setExerciseSubmitStatus(decodedUrl, status);
      return urlWithSubmittedStatus;
    }
    return `${this.redirectBack}&isSubmitted=${status}`;
  }

}
