<ng-container *ngIf="!loadingSkeleton; else loadSkeleton">
  <div class="content-container p-24">
    <div class="view-exercise-container">
      <div class="d-flex align-items-center justify-content-between mb-24">
        <div class="d-flex align-items-center">
          <svg-icon class="mr-2 cursor-pointer" src="../../../assets/images/back-arrow.svg" [routerLink]="['/exercise']"
            queryParamsHandling="preserve">
          </svg-icon>
          <span class="page-title text-break pb-2 ms-2 mb-0">
            {{title}}
          </span>
        </div>

        <!-- <div class="primary-text cursor-pointer ml-24">
        Try Exercise
      </div> -->
      </div>

      <div class="view-exercise-card">
        <div class="exercise-details">
          <div class="body-sub-text mb-24">
            <div class="description" [innerHtml]="descriptionFetched" *ngIf="descriptionFetched; else noDescription">
            </div>
            <ng-template #noDescription>
              No Description available.
            </ng-template>
          </div>

          <div class="d-flex align-items-center exercise-card-footer">
            <div class="d-flex align-items-center">
              <span class="body-sub-text">Status:</span>
              <!-- <span class="d-inline-flex align-items-center justify-content-center level ms-1"
              [ngClass]="{'status-draft' :exerciseListCardItem.status == 'Draft'  , 'status-yet-to-start':exerciseListCardItem.status =='Yet to start' ,'status-expired':exerciseListCardItem.status == 'Expired' }"> -->
              <span class="d-inline-flex align-items-center justify-content-center level ms-1 "
                [ngClass]="{'status-ongoing':exerciseStatus == 'Ongoing'  , 'status-draft' :exerciseStatus == 'Draft'  ,'status-yet-to-start':exerciseStatus =='Yet to start' ,'status-expired':exerciseStatus == 'Expired' }">
                {{exerciseStatus}}
              </span>
            </div>

            <div class="body-sub-text">
              Validity:<span class="ff-semibold lh-16 ms-1 text-nowrap">
                {{startDate| dateFormat}} - {{endDate| dateFormat}}</span>
            </div>


            <div class="body-sub-text" *ngIf="!!duration">
              <span class="text-nowrap">Time Limit:</span>
              <span class="ff-semibold lh-16 ms-1 text-nowrap">
                {{displayTime}}</span>
            </div>

            <div class="body-sub-text">
              Marks:<span class="ff-semibold lh-16 ms-1 text-nowrap">
                {{marks.toFixed(2)}}</span>
            </div>

            <div class="body-sub-text d-flex align-items-center">
              <span class="text-nowrap">Language & Archetype:</span>
              <span class="ff-semibold lh-16 ms-1">
                {{formattedArchetypes}} </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="heading-2 mt-32 mb-24">
      {{ projectCountText }}
    </div>

    <scrollbar class="scrollbar">
      <div class="projectlist-check" *ngFor="let projectListCardItem of projectsFetched; index as index">
        <app-projectlistcard [projectListCardItem]="projectListCardItem"
          [displayMode]="ProjectListCardModes.VIEW_EXERCISE_DETAILS" (deletedProj)="removeProject($event)">
        </app-projectlistcard>
      </div>
    </scrollbar>

  </div>
</ng-container>

<ng-template #loadSkeleton>
  <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.ViewExerciseSkeletonComponent" [skeletonUiRepeat]="1">
  </ng-template>
</ng-template>