<div class="pre-submission-container">
  <div class="pre-submission-body">
    <div class="d-flex align-items-center justify-content-between mb-24">
      <h1 class="page-title mb-0">{{exercisePlayerService.attemptData.exerciseTitle}}</h1>
      <div class="d-flex align-items-center justify-content-end">
        <div class="time-left level-easy" *ngIf="kind == 'quiz' && exercisePlayerService.attemptData.timeLimit !==0">
          Time left - {{ (projectPlayerService.countdown$ | async) || '00:00:00' }}
        </div>

        <div class="dropdown flat-btn theme-dropdown">
          <span class="dropdown-toggle align-items-center cursor-pointer d-flex" data-bs-toggle="dropdown"
            aria-expanded="false">
            <img class="me-1" src="../../../../assets/images/theme.svg">
            <p translate="projectBank.projectPlayer.tryProject.theme"></p>
          </span>

          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item" (click)="toggleTheme('light')"
                [ngClass]="selectedStatus ? 'theme-not-selected' : 'theme-selected'">
                <svg-icon class="theme-selected-icon pe-2" src="../../../../assets/images/tick.svg"></svg-icon>
                <svg-icon class="me-2" src="../../../../assets/images/projectPlayer/light_theme_icon.svg"></svg-icon>
                <p translate="projectBank.projectPlayer.tryProject.light"></p>
              </a>
            </li>
            <li>
              <a class="dropdown-item" (click)="toggleTheme('dark')"
                [ngClass]="selectedStatus ? 'theme-selected' : 'theme-not-selected'">
                <svg-icon class="theme-selected-icon pe-2" src="../../../../assets/images/tick.svg"></svg-icon>
                <svg-icon class="me-2" src="../../../../assets/images/projectPlayer/dark_theme_icon.svg"></svg-icon>
                <p translate="projectBank.projectPlayer.tryProject.dark"></p>
              </a>
            </li>
          </ul>
        </div>
      </div>

    </div>
    <div class="project-status-grid">
      <div class="project-status status-unanswered">
        <span>
          {{attemptDataDetailsProjects.length-exercisePlayerService.AnswerStatusCount()}}
          <img class="ms-2" src="../../../../../assets/images/presubmission/projects-unanswered.svg">
        </span>
        <p class="body-text">Projects Unanswered</p>
      </div>
      <div class="project-status status-review">
        <span>
          {{exercisePlayerService.markedForReviewCount()}}
          <img class="ms-2" src="../../../../../assets/images/presubmission/projects-review.svg">
        </span>
        <p class="body-text">Projects for Review</p>
      </div>
      <div class="project-status status-answered">
        <span>
          {{exercisePlayerService.AnswerStatusCount()}}
          <img class="ms-2" src="../../../../../assets/images/presubmission/projects-answered.svg">
        </span>
        <p class="body-text">Projects Answered</p>
      </div>
      <div>
      </div>
      <div>
      </div>
    </div>

    <div class="project-list-table-container">
      <div class="table-responsive">
        <scrollbar>
          <table class="table table-bordered">
            <thead class="table-light">
              <tr>
                <th scope="col">Project</th>
                <th scope="col">Language & Archetype</th>
                <th scope="col">Marks</th>
                <th scope="col">Status</th>
                <th scope="col">View</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let projectDetails of attemptDataDetailsProjects; let i= index">
                <td>{{projectDetails.title}}</td>
                <td>
                  <ng-container *ngFor="let archetype of projectDetails.archetypes; let last = last">
                    {{ archetype.language }} > {{ archetype.archetypeId }}{{ !last ? ', ' : '' }}
                  </ng-container>
                </td>
                <td>{{projectDetails.maxMarks}}</td>
                <td>
                  <div class="d-flex align-items-center">
                    <img *ngIf="exercisePlayerService.getAnswerStatus(projectDetails.projectId)"
                      src="../../../../../assets/images/projectPlayer/answered.svg">
                    <img *ngIf="!exercisePlayerService.getAnswerStatus(projectDetails.projectId)"
                      src="../../../../../assets/images/presubmission/projects-unanswered.svg">
                    <span class="bookmark-icon">
                      <img class="ml-16" *ngIf="exercisePlayerService.getMarkedForReview(projectDetails.projectId)"
                        src="../../../../../assets/images/projectPlayer/for-review.svg">
                      <img class="ml-16" *ngIf="!exercisePlayerService.getMarkedForReview(projectDetails.projectId)"
                        src="../../../../../assets/images/projectPlayer/unbookmark.svg">
                    </span>
                  </div>
                </td>
                <td>
                  <span [ngClass]="{'disabled':isExerciseSubmitEventTriggered}" class="primary-text cursor-pointer"
                    (click)="viewProject(i)">View Project</span>
                </td>
              </tr>
            </tbody>
          </table>
        </scrollbar>
      </div>

    </div>

  </div>
  <div class="pre-submission-footer d-flex justify-content-between">
    <button [disabled]="isExerciseSubmitEventTriggered" class="btn btn-secondary-grey" (click)="redirectToLumen()">Exit
      Exercise</button>
    <div class="d-flex align-items-center">
      <button [disabled]="isExerciseSubmitEventTriggered" class="btn btn-secondary-orange submit-exercise"
        (click)="backToTest()">Back to Exercise</button>
      <button [disabled]="isExerciseSubmitEventTriggered" class="btn btn-primary submit-exercise"
        (click)="submitExercise()">Confirm Submission</button>
    </div>
  </div>
</div>