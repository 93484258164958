<div *ngIf="overlay===true">
  <app-overlay-skeleton />
</div>
<div class="setup-container">
  <div class="inner-container">
    <div class="setup-content">
      <scrollbar class="scrollbar">
        <div class="add-scroll mt-24">
          <form [formGroup]="setUpForm" class="px-24">

            <div class="form-group">
              <label class="form-label required" translate="projectBank.manipulation.setup.setupTitle"></label>
              <input class="form-control" formControlName="title" placeholder="Add Title">
            </div>
            <div class="ng-select-dropdown-container">
              <div class="col">
                <div class="form-group">
                  <label class="form-label required" translate="projectBank.manipulation.setup.level"></label>
                  <ng-select [items]="level" [clearable]="false" bindLabel="name" bindValue="name" class=""
                    placeholder="Select Level" [closeOnSelect]="true" formControlName="selectedLevel">
                  </ng-select>
                </div>
              </div>
              <div class="col" [ngClass]="{'disabled':projectService.projectMode === projectPlayerModes.EDIT}">
                <div class="form-group custom-clear-all multi-ng-select">
                  <label class="form-label required" translate="projectBank.manipulation.setup.language"></label>
                  <ng-select [items]="languages" bindLabel="name" bindValue="value" [multiple]="true" [clearable]="true"
                    [clearOnBackspace]="true" [closeOnSelect]="false" placeholder="Select Language"
                    formControlName="selectedLang" (change)="onLangSelectChange($event, 'selectedLang')">
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                      <input id="item-{{ index }}" type="checkbox" class="custom-checkbox" [ngModel]="item$.selected"
                        [ngModelOptions]="{ standalone: true }" />
                      {{ item.name }}
                    </ng-template>
                  </ng-select>
                </div>
              </div>
              <div class="col"
                [ngClass]="{'disabled':setUpForm.value.selectedLang.length===0 || projectService.projectMode === projectPlayerModes.EDIT}">
                <div class="form-group custom-clear-all multi-ng-select archetype-dropdown">
                  <label class="form-label required" translate="projectBank.manipulation.setup.archetype"></label>
                  <ng-select [items]="filteredVersionArray" bindLabel="label" [closeOnSelect]="false"
                    bindValue="archetypeId" groupBy="archetypes" [multiple]="true" [clearable]="true"
                    [clearOnBackspace]="true" placeholder="Select Archetype" formControlName="selectedArch"
                    (change)="restructureLanguageAndArchetypes()">
                    <ng-template ng-optgroup-tmp let-parent="item">
                      {{ parent.label }}
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                      <input id="item-{{ index }}" type="checkbox" class="custom-checkbox" [ngModel]="item$.selected"
                        [ngModelOptions]="{ standalone: true }" />
                      {{ item.label }}
                    </ng-template>
                  </ng-select>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="form-label required" translate="projectBank.manipulation.setup.problem-statement"></label>
              <app-ck-editor [ckeditorFormControl]="setUpForm.controls['problemStatement']"
                [config]="{placeholder: problemStatementPlaceholder}"></app-ck-editor>
            </div>
            <div class="form-group">
              <label class="form-label" translate="projectBank.manipulation.setup.instruction"></label>
              <app-ck-editor [ckeditorFormControl]="setUpForm.controls['instruction']"
                [config]="{placeholder: instructionPlaceholder}"></app-ck-editor>
            </div>
            <div class="form-group mb-0 pb-24">
              <label class="form-label" translate="projectBank.manipulation.setup.example"></label>
              <app-ck-editor [ckeditorFormControl]="setUpForm.controls['example']"
                [config]="{placeholder: examplePlaceholder}"></app-ck-editor>
            </div>

          </form>
        </div>
      </scrollbar>
    </div>
    <div class="d-flex justify-content-end footerBtn">
      <button type="button" class="btn btn-secondary-grey" (click)="cancelFromSetup()"
        [disabled]="!projectService.isSaveAsDraftOrCancelEnable" translate="projectBank.manipulation.setup.cancel">
      </button>
      <button type="button" class="btn btn-secondary-orange mx-4"
        [disabled]="!setUpForm.valid || !projectService.isSaveAsDraftOrCancelEnable" (click)="saveSetupForm(true)"
        translate="projectBank.manipulation.setup.save&draft">
      </button>
      <button type="button" class="btn btn-primary" [disabled]="!setUpForm.valid" (click)="saveSetupForm()"
        translate="projectBank.manipulation.setup.save&continue">
      </button>
    </div>
  </div>
</div>