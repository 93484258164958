/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, firstValueFrom, interval, takeWhile } from 'rxjs';
import { LayoutModes } from 'src/app/enums/layoutModes';
import { ProjectEventsKey } from 'src/app/enums/projectEventsKey';
import { ProjectPlayerModes, Themes } from 'src/app/enums/PlayerModes';
import { Archetype } from 'src/app/models/project-list-card';
import { AddContent, BlackBoxInterface, GetDirectory, GetProjectFileContent, ProjectDetails, exportFileToMonacoEditor, compileAndRun, EnableEvalWhiteBoxTestCases, EnableUserWhiteBoxTestCases, IAutoSaveFile, Tree, urlpayload, IDeleteDirectory, IRenameDirectory } from 'src/app/models/projectInterface';
import { SocketService } from 'src/app/services/socket.service';
import { DBpayload, IAttemptData, IAttemptProjectDetails, IMarkedForReview, ISubmitProject } from 'src/app/models/attemptInterface';
import { AttemptEventKey } from 'src/app/enums/attemptEventKeys';
import { savePractice } from 'src/app/models/practiceInterface';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/services/storage.service';
import { StorageKey } from 'src/app/enums/storageKey';
import { DialogService } from 'src/app/services/dialog.service';
import { CommonService } from 'src/app/services/common.service';
import { Dialog } from 'src/app/models/dialog';

@Injectable()
export class ProjectPlayerService {
  public tabs: exportFileToMonacoEditor[] = [];

  constructor(protected socketService: SocketService, protected storageService: StorageService, protected dialogService: DialogService, protected commonService: CommonService) { }
  public projectId!: string;
  exerciseId!: string;
  exerciseIdTry: string | undefined;
  public archetypes!: Archetype[];
  public attemptId!: string;
  public customInput = {};
  public blackBox: string[] = [];
  public projectDetails!: ProjectDetails;
  public mode!: ProjectPlayerModes;
  public layout = LayoutModes.SIDE;
  public selectedArchetype!: string;
  public selectedLanguage!: string;
  public activeTheme = new Subject<Themes>();
  private addTabSubject = new Subject<exportFileToMonacoEditor>();
  private highLightTabSubject = new Subject<exportFileToMonacoEditor>();
  private clearTabSubject = new Subject<void>();
  clearTab$ = this.clearTabSubject.asObservable();
  public submitState!: boolean;
  public projectReviewed = 0;
  public isMarkedForReviewIDs = new Map();
  public evaluateData = new Map();
  public answeredStatusIds = new Map();
  public projectsAnswered = 0;
  public timeLimitInSeconds!: number;
  public projectsUnAnswered = 0;
  public attemptData!: IAttemptData;
  public countdownSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  countdown$: Observable<string> = this.countdownSubject.asObservable();
  public lastSavedStatusIds = new Map();
  public treeDataSolution!: Tree;
  public viewSolutionLayout = LayoutModes.NONE;
  public lastSavedArchetype = new Map();
  public isReAttempt = false;
  public redirectBack!: string;
  public userId!: string;
  public whiteUser = new Subject<boolean>();
  public whiteEval = new Subject<boolean>();
  public projectArchetypesMap: Map<string, string[]> = new Map();
  storedUrlToken!: string;
  public addResult = new Subject<string>();
  public getResult = new Subject<boolean>();
  getResult$ = this.getResult.asObservable();
  public initSqlUploader = new Subject<File | null>();
  initSqlUploader$ = this.initSqlUploader.asObservable();
  public initFileUploaded = new Subject<void>();
  initFileUploaded$ = this.initFileUploaded.asObservable();
  public deleteFileChecker = new Subject<string | undefined>();
  deleteFileListener$ = this.deleteFileChecker.asObservable();
  clearTab() {
    this.tabs = [];
    this.clearTabSubject.next();
  }

  listenAddTabToMonacoEditor() {
    return this.addTabSubject.asObservable();
  }

  triggerDisplayFileInMonacoEditor(obj: exportFileToMonacoEditor) {
    this.addTabSubject.next(obj);
  }

  listenHighLightActiveFile() {
    return this.highLightTabSubject.asObservable();
  }

  triggerHighLightActiveFile(obj: exportFileToMonacoEditor) {
    this.highLightTabSubject.next(obj);
  }
  listenThemeChange() {
    return this.activeTheme.asObservable();
  }

  triggerThemeChange(obj: Themes) {
    this.activeTheme.next(obj);
  }

  getDirectoryDetails(obj: GetDirectory) {
    return this.socketService.getDataFromSocket(ProjectEventsKey.GET_DIRECTORY_DETAILS, ProjectEventsKey.DIRECTORY_DETAILS_SUCCESS, obj, true);
  }

  requestProjectFileContent(obj: GetProjectFileContent) {
    return this.socketService.getDataFromSocket(ProjectEventsKey.GET_FILE_CONTENT, ProjectEventsKey.FILE_CONTENT_SUCCESS, obj, true);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  SaveContentOfFile(obj: any) {
    return this.socketService.getDataFromSocket(ProjectEventsKey.SAVE, ProjectEventsKey.SAVED, obj, true);
  }
  saveBlackBoxTestCase(blackBoxObj: BlackBoxInterface) {
    return this.socketService.getDataFromSocket(ProjectEventsKey.ADD_TEST_CASES, ProjectEventsKey.ADD_TEST_CASES_SUCESS, blackBoxObj);
  }

  addFileOrDirectory(payload: AddContent) {
    return this.socketService.getDataFromSocket(ProjectEventsKey.ADD_FILE_DIRECTORY, ProjectEventsKey.FILE_DIRECTORY_ADDED, payload, true);
  }

  addFileOrDirectoryFailure() {
    return this.socketService.listenToEvent(ProjectEventsKey.ADD_FILE_DIRECTORY_FAILURE);
  }

  saveBlackBoxTestCaseFailure() {
    return this.socketService.listenToEvent(ProjectEventsKey.ADD_TEST_CASES_FAILURE);
  }

  async saveAllFiles() {
    const payload: IAutoSaveFile = {
      [this.mode !== ProjectPlayerModes.PRACTISE ? 'projectId' : 'practiceId']: this.projectId,
      archetypeId: this.selectedArchetype,
      ...(this.exerciseIdTry && { exerciseId: this.exerciseIdTry }),
    };
    payload.files = this.tabs.map(item => {
      return {
        content: item.code,
        path: item.path
      };
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const executor = (resolve: any, reject: (error: boolean) => void) => {
      firstValueFrom(this.SaveContentOfFile(payload)).then(() => { resolve(true); });
      firstValueFrom(this.socketService.listenToEventWithoutHistory(ProjectEventsKey.SAVE_FAILED)).then(() => { reject(false); });
      firstValueFrom(this.socketService.listenToEventWithoutHistory(AttemptEventKey.VALIDATION_ERROR)).then(() => { reject(false); });
    };
    const saveFilePromise = new Promise(executor);
    return saveFilePromise;
  }
  // async autoSave(): Promise<boolean> {
  //   throw new Error('Method not implemented.');
  // }
  async autoSave(): Promise<boolean> {
    const payload: savePractice = {
      practiceId: this.projectId,
      archetypeId: this.selectedArchetype,
      files: []
    };
    if (this.getSubmitState()) {
      payload.files = this.tabs.map(item => {
        return {
          content: item.code,
          path: item.path
        };
      });
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const executor = (resolve: any, reject: (error: boolean) => void) => {
      firstValueFrom(this.autoSaveContentOfFilePractise(payload)).then((data) => {
        this.setLastSavedStatus(this.projectId, data.files[0].lastSavedOn);
        resolve(true);
      });
      firstValueFrom(this.socketService.listenToEventWithoutHistory(ProjectEventsKey.SAVE_FAILED)).then(() => { reject(false); });
      firstValueFrom(this.socketService.listenToEventWithoutHistory(AttemptEventKey.VALIDATION_ERROR)).then(() => { reject(false); });
    };
    const saveFilePromise = new Promise<boolean>(executor);
    return saveFilePromise;
  }

  autoSaveContentOfFilePractise(payload: savePractice) {
    return this.socketService.getDataFromSocket(AttemptEventKey.AUTO_SAVE, AttemptEventKey.AUTO_SAVE_OK, payload, true);
  }

  enableWhiteBoxTest(payload: EnableUserWhiteBoxTestCases | EnableEvalWhiteBoxTestCases) {
    return this.socketService.getDataFromSocket(ProjectEventsKey.ENABLE_WHITEBOX_TESTS, ProjectEventsKey.WHITEBOX_TESTS_ENABLED, payload);
  }

  enableWhiteBoxTestFailure() {
    return this.socketService.listenToEvent(ProjectEventsKey.ENABLE_WHITEBOX_TESTS_FAILURE);
  }

  compileAndRun(payload: compileAndRun) {
    return this.socketService.getDataFromSocket(ProjectEventsKey.RUN, ProjectEventsKey.FETCHING_META_DATA, payload, true);
  }
  startingExecution() {
    return this.socketService.listenToEventWithoutHistory(ProjectEventsKey.STARTING_EXECUTION);
  }
  default_run_result() {
    return this.socketService.listenToEventWithoutHistory(ProjectEventsKey.DEFAULT_RUN_RESULT);
  }
  compilation_success() {
    return this.socketService.listenToEventWithoutHistory(ProjectEventsKey.COMPILATION_SUCCESS);
  }
  userBlackBoxTests() {
    return this.socketService.listenToEventWithoutHistory(ProjectEventsKey.USER_BLACK_BOX_TESTS);
  }
  evaluatorBlackBoxTests() {
    return this.socketService.listenToEventWithoutHistory(ProjectEventsKey.EVALUATOR_BLACK_BOX_TESTS);
  }
  program_executed() {
    return this.socketService.listenToEventWithoutHistory(ProjectEventsKey.PROGRAM_EXECUTED);
  }
  compile_run_tests_failur() {
    return this.socketService.listenToEventWithoutHistory(ProjectEventsKey.COMPILE_RUN_TESTS_FAILUR);
  }
  program_execution_failed() {
    return this.socketService.listenToEventWithoutHistory(ProjectEventsKey.PROGRAM_EXECUTION_FAILED);
  }
  extract_zip_failure() {
    return this.socketService.listenToEventWithoutHistory(ProjectEventsKey.EXTRACT_ZIP_FAILURE);
  }
  project_zip_failure() {
    return this.socketService.listenToEventWithoutHistory(ProjectEventsKey.PROJECT_ZIP_FAILURE);
  }
  compilation_failed() {
    return this.socketService.listenToEventWithoutHistory(ProjectEventsKey.COMPILATION_FAILED);
  }

  clearCustomInputData() {
    this.customInput = {};
  }
  link_generated() {
    return this.socketService.listenToEventWithoutHistory(ProjectEventsKey.LINK_GENERATED);
  }
  kill_port(obj: urlpayload) {
    return this.socketService.sendMessage(ProjectEventsKey.KILL_PORT, obj);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // startTimer(arg0: number) {
  //   throw new Error('Method not implemented.');
  // }

  // stopTimer() {
  //   throw new Error('Method not implemented.');
  // }

  // listenToTimer(): Observable<number> {
  //   throw new Error('Method not implemented.');
  // }

  listenProjectChange(): Observable<IAttemptProjectDetails> {
    throw new Error('Method not implemented.');
  }
  getLastSavedStatus(projectId: string): string {
    return this.lastSavedStatusIds.get(projectId);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  triggerProjectChange(_obj: IAttemptProjectDetails) {
    throw new Error('Method not implemented.');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  triggerToDisplayProjectDetails(_obj: IAttemptProjectDetails) {
    throw new Error('Method not implemented.');
  }

  listenToDisplayProjectDetails(): Observable<IAttemptProjectDetails> {
    throw new Error('Method not implemented.');
  }

  setSubmitState(submitState: boolean) {
    this.submitState = submitState;
  }

  getSubmitState() {
    return this.submitState;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submitExercise() {
    throw new Error('Method not implemented.');
  }
  dbCredentials(payload: DBpayload) {
    return this.socketService.getDataFromSocket(AttemptEventKey.GET_DB_CREDENTIALS, AttemptEventKey.DB_CREDENTIALS, payload, true);
  }
  startCountdown() {
    interval(1000)
      .pipe(
        takeWhile(() => this.timeLimitInSeconds > 0)
      )
      .subscribe(() => {
        this.timeLimitInSeconds--;
        this.updateCountdown();
      });
  }

  updateCountdown() {
    const hours = Math.floor(this.timeLimitInSeconds / 3600);
    const minutes = Math.floor((this.timeLimitInSeconds % 3600) / 60);
    const seconds = this.timeLimitInSeconds % 60;
    const countdown = `${this.formatUnit(hours)}:${this.formatUnit(minutes)}:${this.formatUnit(seconds)}`;
    this.countdownSubject.next(countdown);
  }

  formatUnit(unit: number): string {
    return unit < 10 ? '0' + unit : unit.toString();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setMarkedForReview(projectId: string, status: boolean) {
    throw new Error('Method not implemented.');
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getMarkedForReview(projectId: string): boolean {
    throw new Error('Method not implemented.');
  }
  markedForReviewCount(): number {
    throw new Error('Method not implemented.');
  }
  setLastSavedStatus(projectId: string, time: string) {
    this.lastSavedStatusIds.set(projectId, time);
  }

  // setLastSavedStatus(projectId: string, status: string) {
  //   throw new Error('Method not implemented.');
  // }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setAnswerStatus(projectId: string, status: boolean) {
    throw new Error('Method not implemented.');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getAnswerStatus(projectId: string): boolean {
    throw new Error('Method not implemented.');
  }

  AnswerStatusCount(): number {
    throw new Error('Method not implemented.');
  }

  completeEvaluation(data: string) {
    throw new Error('Method not implemented.');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateProjectStatus(status = 'inProgress'): Observable<any> {
    throw new Error('Method not implemented.');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  saveAllFilesInAttempt(): Observable<any> {
    throw new Error('Method not implemented.');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
  projectMarkedForReview(payload: IMarkedForReview): Observable<any> {
    throw new Error('Method not implemented.');
  }

  updateProjectArchetypesMap(projectId: string, archetypeId: string) {
    throw new Error('Method not implemented.');
  }

  listenSubmitExercise() {
    throw new Error('Method not implemented.');
  }

  fetchAttemptDetails(attemptId: string): Observable<any> {
    throw new Error('Method not implemented.');
  }

  saveFeedback(data: any): Observable<any> {
    throw new Error('Method not implemented.');
  }

  setFormValues(data: any, data2: any) {
    throw new Error('Method not implemented.');
  }
  getFormValues(data: any) {
    throw new Error('Method not implemented.');
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submitProject(payload: ISubmitProject): Observable<any> {
    throw new Error('Method not implemented.');
  }

  listenDataBaseConnected() {
    return this.socketService.listenToEventWithoutHistory(ProjectEventsKey.DATABASE_CONNECTED);
  }
  listenQueryExecuted() {
    return this.socketService.listenToEventWithoutHistory(ProjectEventsKey.QUERY_EXECUTED);
  }
  listenQueryExecutionFailed() {
    return this.socketService.listenToEventWithoutHistory(ProjectEventsKey.QUERY_EXECUTION_FAILED);
  }
  dbmsExecutionSuccess() {
    return this.socketService.listenToEventWithoutHistory(ProjectEventsKey.DBMS_EXECUTION_SUCCESS);
  }
  dbmsExecutionFailed() {
    return this.socketService.listenToEventWithoutHistory(ProjectEventsKey.DBMS_EXECUTION_FAILED);
  }

  userWhiteBoxTests() {
    return this.socketService.listenToEventWithoutHistory(ProjectEventsKey.USER_WHITE_BOX_TESTS);
  }

  evaluatorWhiteBoxTests() {
    return this.socketService.listenToEventWithoutHistory(ProjectEventsKey.EVALUATOR_WHITE_BOX_TESTS);
  }

  testExecutionFailed() {
    return this.socketService.listenToEventWithoutHistory(ProjectEventsKey.TEST_EXECUTION_FAILED);
  }

  setLastSavedArchetype(projectId: string, archetype: string) {
    this.lastSavedArchetype.set(projectId, archetype);
  }

  getLastSavedArchetype(projectId: string): string {
    return this.lastSavedArchetype.get(projectId);
  }

  triggerDisplayResultFile(obj: string) {
    this.addResult.next(obj);
  }

  listenDisplayResultFile() {
    return this.addResult.asObservable();
  }

  async uploadFile(initSql: File) {
    const uploadedFile = new FormData();
    uploadedFile.append('file', initSql);
    const keyId = this.mode !== ProjectPlayerModes.PRACTISE ? 'projectId' : 'practiceId';
    uploadedFile.append(keyId, this.projectId);
    uploadedFile.append('archetypeId', this.selectedArchetype);
    uploadedFile.append('channelId', this.socketService.socketConfig.body.channelId);
    const url = environment.apiBaseUrl + 'api/code-runner-service/upload/uploadFile';
    return fetch(url, {
      method: "POST",
      headers: {
        'Authorization': this.socketService.socketConfig.header.Authorization,
        'organizationId': this.storageService.get(StorageKey.OrganizationId)
      },
      body: uploadedFile,
    }).then((response) => {
      return response.json();
    });
  }

  // listenExecutingQueries() {
  //   return this.socketService.listenToEventWithoutHistory(ProjectEventsKey.EXECUTING_QUERIES);
  // }
  listenInitSqlFileExecuted() {
    return this.socketService.listenToEventWithoutHistory(ProjectEventsKey.INIT_SQL_FILE_EXECUTED);
  }

  deleteSqlFileContent() {
    const deletePayload = {
      projectId: this.projectId,
      archetypeId: this.selectedArchetype
    };
    return this.socketService.sendMessage(ProjectEventsKey.DELETE_SQL_FILE_CONTENT, deletePayload);
  }

  async deleteSqlTrigger() {
    const message = this.commonService.translateText('projectBank.projectPlayer.deleteSqlFile');
    const note = this.commonService.translateText('confirmModal.noteFile');
    const dialog: Dialog = { title: { translationKey: message }, note: { translationKey: note } };
    const confirmation = await this.dialogService.showConfirmDialog(dialog);
    if (confirmation) {
      this.deleteSqlFileContent();
    }
  }

  listenDeleteFileContent() {
    return this.socketService.listenToEventWithoutHistory(ProjectEventsKey.DELETED_INIT_SQL_FILE_CONTENT);
  }

  listenValidationError() {
    return this.socketService.listenToEventWithoutHistory(ProjectEventsKey.VALIDATION_ERROR);
  }

  DeleteFileFromTree(obj: IDeleteDirectory) {
    return this.socketService.getDataFromSocket(ProjectEventsKey.REMOVE_FILE_DIRECTORY, ProjectEventsKey.FILE_DIRECTORY_REMOVED, obj, true);
  }

  renameFileFromTree(obj: IRenameDirectory) {
    return this.socketService.getDataFromSocket(ProjectEventsKey.RENAME_FILE_DIRECTORY, ProjectEventsKey.FILE_DIRECTORY_RENAMED, obj, true);
  }

  getExerciseSubmitStatus(decodedUrl: string) {
    const queryString = decodedUrl.split('?')[1];
    const queryParams = new URLSearchParams(queryString);
    return queryParams.get('isSubmitted');
  }

  setExerciseSubmitStatus(decodedUrl: string, status: boolean) {
    const [queryUrl, queryString] = decodedUrl.split('?');
    const queryParams = new URLSearchParams(decodedUrl);
    queryParams.set('isSubmitted', status ? 'true' : 'false');
    return queryUrl + '?' + queryString;
  }

  setUrl(status: boolean): string {
    const decodedUrl = decodeURIComponent(this.redirectBack);
    const isSubmitted = this.getExerciseSubmitStatus(decodedUrl);
    if (isSubmitted) {
      const urlWithSubmittedStatus = this.setExerciseSubmitStatus(decodedUrl, status);
      return urlWithSubmittedStatus;
    }
    return `${this.redirectBack}&isSubmitted=${status}`;
  }

  listenMainFileNotFound() {
    return this.socketService.listenToEventWithoutHistory(ProjectEventsKey.MAIN_FILE_NOT_FOUND);
  }

}
