import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { ProjectEventsKey } from 'src/app/enums/projectEventsKey';
import { ProjectPlayerModes } from 'src/app/enums/PlayerModes';
import { AddProjects } from 'src/app/models/exerciseInterface';
import { IGetProjectDetails, ProjectDetails, RubricsPayload, SetupInterface } from 'src/app/models/projectInterface';
import { SocketService } from 'src/app/services/socket.service';

@Injectable()
export class ProjectService {
  constructor(private socketService: SocketService) { }
  public projectId!: string;
  public archetypes!: { language: string, archetypeId: string, label: string, targetFileName: string, sourceFileName: string }[];
  public projectDetails!: ProjectDetails;
  public isUserBlackBoxInputDisable = true;
  public isEvaluatorBlackBoxInputDisable = true;
  public isUserWhiteBoxInputDisable = true;
  public isEvaluatorWhiteBoxInputDisable = true;
  public projectMode = ProjectPlayerModes.CREATE;
  public projectDetailsStateBroadcaster = new ReplaySubject;
  public isSaveAsDraftOrCancelEnable = true;
  public exerciseId: string | undefined;
  setProjectId(projectId: string) {
    this.projectId = projectId;
  }

  getProjectId() {
    return this.projectId;
  }

  saveSetupForm(obj: SetupInterface) {
    return this.socketService.getDataFromSocket(ProjectEventsKey.CREATE_PROJECT, ProjectEventsKey.PROJECT_CREATED, obj, true);
  }
  public getConfig() {
    const payload = {};
    return this.socketService.getDataFromSocket(ProjectEventsKey.GET_PROJECT_CONFIGURATION, ProjectEventsKey.PROJECT_CONFIGURATION, payload);
  }

  saveRubrics(rubricsPayload: RubricsPayload) {
    rubricsPayload.projectId = this.projectId;
    return this.socketService.getDataFromSocket(ProjectEventsKey.ADD_RUBRICS, ProjectEventsKey.ADD_RUBRICS_SUCCESS, rubricsPayload, true);
  }

  addProjectToExercise(addProjectsPayload: AddProjects) {
    return this.socketService.getDataFromSocket(ProjectEventsKey.ADD_PROJECTS, ProjectEventsKey.PROJECTS_ADDED, addProjectsPayload, true);
  }

  rubricsSaveFailure() {
    return this.socketService.listenToEventWithoutHistory(ProjectEventsKey.ADD_RUBRICS_FAILURE);
  }

  projectMetaDataCreated() {
    return this.socketService.listenToEventWithoutHistory(ProjectEventsKey.PROJECT_METADATA_CREATED);
  }

  getProjectDetails() {
    const payload: IGetProjectDetails = {
      projectId: this.projectId,
    };
    if (this.exerciseId) {
      payload.exerciseId = this.exerciseId;
    }
    return this.socketService.getDataFromSocket(ProjectEventsKey.GET_PROJECT_DETAILS, ProjectEventsKey.PROJECT_DETAILS, payload, true);
  }

  updateSetupForm(obj: SetupInterface) {
    return this.socketService.getDataFromSocket(ProjectEventsKey.UPDATE_PROJECT, ProjectEventsKey.PROJECT_UPDATED, obj, true);
  }
  updateSetupFormFailure() {
    return this.socketService.listenToEvent(ProjectEventsKey.UPDATE_PROJECT_FAILED);
  }
  publishProjectDetailsState() {
    this.projectDetailsStateBroadcaster.next(true);
  }
  listenToProjectDetailsState() {
    return this.projectDetailsStateBroadcaster.asObservable();
  }

  validationEventsError() {
    return this.socketService.listenToEventWithoutHistory(ProjectEventsKey.VALIDATION_ERROR);
  }

  clearAll() {
    this.projectId = '';
    this.archetypes = [{
      language: '',
      archetypeId: '',
      label: '',
      targetFileName: '',
      sourceFileName: ''
    }];
    this.projectDetails = {} as unknown as ProjectDetails;
    this.projectMode = ProjectPlayerModes.CREATE;
  }

}
