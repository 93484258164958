export enum ProjectEventsKey {
  GET_PROJECT_LIST = "get_project_list",
  PROJECT_LIST = "project_list",
  CREATE_PROJECT = "create_project",
  PROJECT_METADATA_CREATING = "project_metadata_creating",
  PROJECT_METADATA_CREATED = "project_metadata_created",
  PROJECT_CREATED = "project_created",
  GET_PROJECT_CONFIGURATION = 'get_project_configuration',
  PROJECT_CONFIGURATION = 'project_configuration',
  GET_DIRECTORY_DETAILS = "get_directory_details",
  DIRECTORY_DETAILS_SUCCESS = "directory_details",
  GET_FILE_CONTENT = "get_file_content",
  FILE_CONTENT_SUCCESS = "file_content",
  ADD_DIRECTORY_TO_PROJECT = "add_directoy_to_project",
  ADDED_DIRECTORY_TO_PROJECT = "added_directoy_to_project",
  ADD_FILE_TO_PROJECT = "add_file_to_project",
  ADD_FILE_TO_PROJECT_SUCCESS = "add_file_to_project_success",
  ADD_FILE_TO_PROJECT_FAILURE = "add_file_to_project_faiLure",
  ENABLE_WHITEBOX_TESTS = "enable_whitebox_test",
  WHITEBOX_TESTS_ENABLED = "whitebox_test_enabled",
  ENABLE_WHITEBOX_TESTS_FAILURE = "enable_whitebox_test_failed",
  SAVE = "save",
  SAVED = "save_ok",
  SAVE_FAILED = "save_failed",
  ADD_RUBRICS = 'add_rubrics',
  ADD_RUBRICS_SUCCESS = 'add_rubrics_success',
  ADD_RUBRICS_FAILURE = 'add_rubrics_failure',
  ADD_TEST_CASES = "add_test_cases",
  ADD_TEST_CASES_SUCESS = "add_test_cases_success",
  ADD_TEST_CASES_FAILURE = "add_test_cases_failure",
  ADD_FILE_DIRECTORY = "add_file_directory",
  FILE_DIRECTORY_ADDED = "file_directory_added",
  ADD_FILE_DIRECTORY_FAILURE = "add_file_directory_failure",
  DELETE_PROJECT = "delete_project",
  PROJECT_DELETED = "project_deleted",
  GET_PROJECT_DETAILS = "get_project_details",
  PROJECT_DETAILS = "project_details",
  UPDATE_PROJECT = "update_project",
  PROJECT_UPDATED = "project_updated",
  UPDATE_PROJECT_FAILED = "update_project_failed",
  CLONE_PROJECT = "clone_project",
  CLONED_PROJECT_METADATA_CREATED = "cloned_project_metadata_created",
  PROJECT_CLONE_FAILURE = "project_clone_failure",
  METADATA_CREATION_FAILURE = "metadata_creation_failure",
  PROJECT_CLONED = "project_cloned",
  OLD_PROJECT_PATH_NOT_FOUND = "old_project_path_not_found",
  RUN = "run",
  FETCHING_META_DATA = "fetching_meta_data",
  STARTING_EXECUTION = "starting_execution",
  DEFAULT_RUN_RESULT = "default_run_result",
  COMPILATION_SUCCESS = "compilation_success",
  USER_BLACK_BOX_TESTS = "userBlackBoxTests",
  EVALUATOR_BLACK_BOX_TESTS = "evalBlackBoxTests",
  PROGRAM_EXECUTED = "program_executed",
  COMPILE_RUN_TESTS_FAILUR = "compile_run_tests_failur",
  PROGRAM_EXECUTION_FAILED = "program_execution_failed",
  EXTRACT_ZIP_FAILURE = "extract_zip_failure",
  PROJECT_ZIP_FAILURE = "project_zip_failure",
  ADD_PROJECTS = 'add_projects',
  PROJECTS_ADDED = 'projects_added',
  DATABASE_CONNECTED = "database_connected",
  QUERY_EXECUTED = "query_executed",
  QUERY_EXECUTION_FAILED = "query_execution_failed",
  DBMS_EXECUTION_SUCCESS = "dbms_execution_success",
  DBMS_EXECUTION_FAILED = "dbms_execution_failed",
  USER_WHITE_BOX_TESTS = "userWhiteBoxTests",
  EVALUATOR_WHITE_BOX_TESTS = "evalWhiteBoxTests",
  COMPILATION_FAILED = "compilation_failed",
  KILL_PORT = "kill_port",
  LINK_GENERATED = "link_generated",
  IMAGE_UPLOADED = "image_uploaded",
  UPLOAD_IMAGE = "upload_image",
  EXECUTING_QUERIES = "executing_queries",
  INIT_SQL_FILE_EXECUTED = "init_sql_file_executed",
  DELETE_SQL_FILE_CONTENT = "delete_sql_file_content",
  DELETED_INIT_SQL_FILE_CONTENT = "deleted_init_sql_file_content",
  VALIDATION_ERROR = "validation_error",
  REMOVE_FILE_DIRECTORY = 'remove_file_directory',
  FILE_DIRECTORY_REMOVED = 'file_directory_removed',
  RENAME_FILE_DIRECTORY = 'rename_file_directory',
  FILE_DIRECTORY_RENAMED = 'file_directory_renamed',
  MAIN_FILE_NOT_FOUND = "main_file_not_found",
  TEST_EXECUTION_FAILED = "test_case_execution_failed"
}