/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-lines-per-function */
/* eslint-disable eqeqeq */

import { Component } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ExerciseBuilderService } from '../exercise-builder.service';
import { ContentStatus, ExerciseStatus, ExerciseType, GradingType, OverallGradeCalculation } from 'src/app/enums/exerciseForm';
import { ActivatedRoute, Router } from '@angular/router';
import { ExerciseBuilderModes } from 'src/app/enums/exerciseBuilderModes';
import { ExerciseDetails, exerciseData } from 'src/app/models/exerciseInterface';
import * as dayjs from 'dayjs';
import { ScrollService } from 'src/app/services/scroll.service';
import { RoutingParams } from 'src/app/enums/routingParameters';
import { Subject, filter, takeUntil } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-exercise-setup',
  templateUrl: './exercise-setup.component.html',
  styleUrls: ['./exercise-setup.component.scss']
})
export class ExerciseSetupComponent {
  subjectToUnsubscribe = new Subject;
  isshowFeedback = false;
  isRangeDisplay = false;
  isDisable = false;
  isPlagiarismDisplay = false;
  isLimitedAttemptsDisplay = false;
  exerciseSetup!: FormGroup;
  rangeGroup!: FormGroup;
  attemptsAndGradeConfig!: FormGroup;
  isPassingCriteria = false;
  isMultipleAttemptDisplay = false;
  durationLimitExercise = false;
  limitedAttempts = false;
  ExerciseType = ExerciseType;
  ContentStatus = ContentStatus;
  GradingType = GradingType;
  OverallGradeCalculation = OverallGradeCalculation;
  exerciseType!: string | null;
  currentDate = dayjs();
  isValidRange = false;
  isCompareRangeValid = false;
  exerciseTypeUrl!: string | null;
  totalNoOfAttempts!: number;
  status = ExerciseStatus.NOT_ATTACHED;
  disableGradingType = false;
  disableGradable = false;
  exerciseStatus = ExerciseStatus;
  // eslint-disable-next-line max-params
  constructor(private fb: FormBuilder, public exerciseBuilderService: ExerciseBuilderService, private activatedRoute: ActivatedRoute, private router: Router, private scrollService: ScrollService, private toastService: ToastrService) {

  }
  ngOnInit() {
    this.exerciseTypeUrl = this.activatedRoute.snapshot.queryParamMap.get('excerciseType') || this.exerciseBuilderService.exerciseDetails.kind;
    this.formInitialize();
    //this.setStartAndEndDateBasedQueryParams();
    // this.setIdealAndDurationValue();
    this.setupDynamicValidation('allowPlagiarism', this.exerciseSetup, ['plagiarismReport']);
    this.setupDynamicValidation('allowMultipleAttempts', this.exerciseSetup, ['attempts']);
    this.setupDynamicValidation('enablePassingCriteria', this.exerciseSetup, ['percentagePassing']);
    this.editExerciseForm();
    this.valueChangesSubscription();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.isDisable = (this.exerciseBuilderService.exerciseDetails.submissions)! > 0 ? true : false;
    if (this.exerciseBuilderService.exerciseDetails.attemptsAndGradeConfig.gradingType === GradingType.NON_GRADABLE_WITHOUT_MARKS && this.exerciseBuilderService.exerciseBuilderMode === ExerciseBuilderModes.EDIT) {
      this.disableGradingType = true;
      this.disableGradable = true;
    } else if (this.exerciseBuilderService.exerciseDetails.attemptsAndGradeConfig.gradingType === GradingType.NON_GRADABLE_WITH_MARKS && this.exerciseBuilderService.exerciseBuilderMode === ExerciseBuilderModes.EDIT) {
      this.disableGradingType = false;
      this.disableGradable = true;
    } else {
      this.disableGradingType = false;
      this.disableGradable = false;
    }
  }

  formInitialize() {
    this.exerciseSetup = this.fb.group({
      title: new FormControl('', Validators.required),
      instruction: new FormControl(''),
      description: new FormControl(''),
      contentStatus: new FormControl(ContentStatus.MANDATORY),
      duration: this.fb.group({
        timeLimit: new FormControl("Not required"),
        startDate: new FormControl(null, [Validators.required]),
        endDate: new FormControl(null, [Validators.required]),
        idealHours: new FormControl(0, [Validators.pattern('^[0-9]*$'), Validators.min(0), Validators.max(23)]),
        idealMins: new FormControl(30, [Validators.pattern('^[0-9]*$'), Validators.min(0), Validators.max(59)]),
        durationMins: new FormControl(30, [Validators.pattern('^[0-9]*$'), Validators.min(0), Validators.max(59)]),
        durationHours: new FormControl(0, [Validators.pattern('^[0-9]*$'), Validators.min(0), Validators.max(23)]),
      }),
      practiceIdealHours: new FormControl(0, [Validators.pattern('^[0-9]*$'), Validators.min(0), Validators.max(23)]),
      practiceIdealMins: new FormControl(30, [Validators.pattern('^[0-9]*$'), Validators.min(0), Validators.max(59)]),
      overallGrade: new FormControl(OverallGradeCalculation.MAXIMUM_OF_ALL_ATTEMPTS),
      attempts: new FormControl(''),
      enablePassingCriteria: new FormControl(false),
      percentagePassing: new FormControl(35, [Validators.pattern('^[0-9]*$'), Validators.min(0), Validators.max(100)]),
      allowMultipleAttempts: new FormControl(false),
      allowFeedback: new FormControl(false),
      feedbackDetails: new FormArray([
        this.initFeedbackDetails()
      ]),
      gradingType: new FormControl(GradingType.GRADABLE, Validators.required),
      maxAttempts: new FormControl(1),
      status: new FormControl(''),
      allowPlagiarism: new FormControl(false),
      plagiarismReport: new FormControl(false),
    });
    const durationGroup = this.exerciseSetup.controls['duration'] as FormGroup;
    durationGroup.setValidators([this.startEndDateValidation(), this.idealAndDurationValidation()]);
    this.setCustomValidators(durationGroup);

    if (this.exerciseTypeUrl === 'practice') {
      durationGroup.get('startDate')?.clearValidators();
      durationGroup.get('endDate')?.clearValidators();
      durationGroup.clearValidators();
      durationGroup.updateValueAndValidity();
    }
  }
  validateTimeInput(controlName: string, relatedControlName: string, errorMessage: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const valueReceived = control.value;
      // console.log(control.value, "val");
      const relatedControl = (this.exerciseSetup.controls['duration'] as FormGroup).controls[relatedControlName];

      if ((valueReceived === '' || valueReceived === 0 || valueReceived === null) &&
        (relatedControl.value === 0 || relatedControl.value === '' || relatedControl.value === null)) {
        relatedControl.setValidators([Validators.required, Validators.min(1)]);
        this.showErrorToast(errorMessage);
      } else if (valueReceived > 1) {
        relatedControl.clearValidators();
      }
      relatedControl.updateValueAndValidity();
      return null;
    };
  }
  setCustomValidators(durationGroup: FormGroup) {
    const idealMinsControl = durationGroup.controls['idealMins'] as FormControl;
    const idealHoursControl = durationGroup.controls['idealHours'] as FormControl;
    const durationMinsControl = durationGroup.controls['durationMins'] as FormControl;
    const durationHoursControl = durationGroup.controls['durationHours'] as FormControl;

    idealMinsControl.setValidators([this.validateTimeInput('idealMins', 'idealHours', 'Ideal Time cannot be 0')]);
    idealHoursControl.setValidators([this.validateTimeInput('idealHours', 'idealMins', 'Ideal Time cannot be 0')]);
    durationMinsControl.setValidators([this.validateTimeInput('durationMins', 'durationHours', 'Duration Time cannot be 0')]);
    durationHoursControl.setValidators([this.validateTimeInput('durationHours', 'durationMins', 'Duration Time cannot be 0')]);

    idealMinsControl.updateValueAndValidity();
    idealHoursControl.updateValueAndValidity();
    durationMinsControl.updateValueAndValidity();
    durationHoursControl.updateValueAndValidity();
  }

  startEndDateValidation(): any {
    return (group: FormGroup) => {
      console.log('date validation triggered ****');
      const startDate = group.get('startDate');
      const endDate = group.get('endDate');
      if (!startDate?.value || !endDate?.value) {
        return;
      }
      if (startDate.value.isBefore(endDate.value, 'minute')) {
        group.setErrors(null);
        return;
      }
      if (startDate.value.isSame(endDate.value, 'minute')) {
        group.controls['endDate'].setErrors({ invalid: true });
        this.showErrorToast("End Time should be Greater than Start Time");
        return;
      }
      group.setErrors({ invalid: true });
      this.showErrorToast("Please enter a Different End Time");
    };
  }

  idealAndDurationValidation(): any {
    return (group: FormGroup) => {
      const startDate = group.get('startDate');
      const endDate = group.get('endDate');
      const idealHours = group.get('idealHours');
      const idealMins = group.get('idealMins');
      let hour: number;
      let totalIdealTime: number;
      setTimeout(() => {
        if (startDate?.value && endDate?.value) {
          hour = endDate.value.diff(startDate.value, 'hour', true);
          console.log(hour, 'difference');
          if (idealMins?.value && idealMins.value > 0) {
            totalIdealTime = (idealHours?.value || 0) + idealMins.value / 60;
            if (totalIdealTime <= hour) {
              group.controls['idealHours'].setErrors(null);
              return;
            }
            else if (totalIdealTime > hour) {
              group.controls['idealHours'].setErrors({ invalid: true });
              this.showErrorToast('Ideal time must be less than the time difference between start date and end date');
              return;
            }
          }
        }
        group.setErrors(null);
      }, 100);
    };
  }

  initFeedbackDetails() {
    return this.fb.group({
      startRange: new FormControl(0, [Validators.min(0), Validators.max(100), this.lowerThan('endRange')]),
      endRange: new FormControl(100, [Validators.min(0), Validators.max(100)]),
      feedback: new FormControl(""),
    });
  }

  public lowerThan(fieldName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const input = control.value;
      const isLower = control.root.get(fieldName)?.value >= input;
      return isLower ? { 'lowerThan': { isLower } } : null;
    };
  }

  addNewOption() {
    this.feedbackArr.push(this.initFeedbackDetails());
    this.validateUniqueValue();
  }

  deleteOption(index: number) {
    this.feedbackArr.removeAt(index);
    this.isValidRange = false;
  }

  get feedbackArr() {
    return this.exerciseSetup.get("feedbackDetails") as FormArray;
  }

  public checkForRangeOverlap(feedbackStartRange: number, feedbackEndRange: number, feedbackDetails: string | any[], index: number) {
    for (let element = 0; element < feedbackDetails.length; element++) {
      if (index !== element) {
        if (
          (feedbackStartRange <= feedbackDetails[element].startRange &&
            feedbackDetails[element].startRange <= feedbackEndRange) ||
          (feedbackStartRange <= feedbackDetails[element].endRange &&
            feedbackDetails[element].endRange <= feedbackEndRange) ||
          (feedbackDetails[element].startRange <= feedbackStartRange &&
            feedbackStartRange <= feedbackDetails[element].endRange) ||
          (feedbackDetails[element].startRange <= feedbackEndRange &&
            feedbackEndRange <= feedbackDetails[element].endRange)
        ) {
          return true;
        }
      }
    }
    return false;
  }

  public validateUniqueValue() {
    const feedbackDetails = this.exerciseSetup.get('feedbackDetails')?.value;
    for (let index = 0; index < feedbackDetails.length; index++) {
      const item = feedbackDetails[index];
      if (item.startRange >= item.endRange) {
        this.isCompareRangeValid = true;
        break;
      }
      if (feedbackDetails.length > 1) {
        this.isValidRange = this.checkForRangeOverlap(item.startRange, item.endRange, feedbackDetails, index) === true ? true : false;
      }
      this.isCompareRangeValid = false;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  enableFormField(event: any, field: string) {
    const isChecked = event.target.checked;
    switch (field) {
      case "plagiarism": this.isPlagiarismDisplay = isChecked;
        if (!isChecked) {
          this.exerciseSetup.controls['plagiarismReport'].setValue(false);
        }
        break;
      case "range":
        this.isRangeDisplay = isChecked;
        break;
      case "multipleAttempt":
        this.isMultipleAttemptDisplay = isChecked;
        if (!isChecked) {
          if (this.isDisable) {
            this.showErrorToast('cannot decrease max attempts if there are submissions');
            return;
          }
          this.exerciseSetup.controls['maxAttempts'].setValue(1);
          this.exerciseSetup.controls['overallGrade'].setValue(OverallGradeCalculation.MAXIMUM_OF_ALL_ATTEMPTS);
        } else {
          this.exerciseSetup.controls['attempts'].setValue('Limited');
          this.changeAttempts();
        }
        break;
      case "passingCriteria":
        this.isPassingCriteria = isChecked;
        if (!isChecked) {
          this.exerciseSetup.controls['percentagePassing'].setValue(0);
        }
        break;
    }
    this.exerciseSetup.updateValueAndValidity();
  }

  valueChangesSubscription() {
    this.exerciseSetup.controls['attempts'].valueChanges.pipe(filter((res: any) => res)).subscribe((res: any) => {
      if (res) {
        this.limitedAttempts = res == 'Limited' ? true : false;
      }
    });
    (this.exerciseSetup.controls['duration'] as FormGroup).controls['timeLimit'].valueChanges.pipe(filter((res: any) => res)).subscribe((res: any) => {
      if (res) {
        this.durationLimitExercise = res == 'Required' ? true : false;
      }
    });

    (this.exerciseSetup.controls['duration'] as FormGroup).controls['durationHours'].valueChanges.pipe(filter((res: any) => res)).subscribe((res: any) => {
      if (res) {
        this.idealTimeSetValue(res, 'idealHours');
      }
    });
    (this.exerciseSetup.controls['duration'] as FormGroup).controls['durationMins'].valueChanges.pipe(filter((res: any) => res)).subscribe((res: any) => {
      if (res) {
        this.idealTimeSetValue(res, 'idealMins');
      }
    });
    this.exerciseSetup.controls['maxAttempts'].valueChanges.pipe(filter((res: any) => res)).subscribe((res: any) => {
      if (res) {
        if (!this.isDisable) {
          this.totalNoOfAttempts = res;
          return;
        }
        if (this.isDisable) {
          const maxAttempts = this.exerciseBuilderService.exerciseDetails.attemptsAndGradeConfig?.maxAttempts;
          if (res === -1 || res >= this.totalNoOfAttempts || res >= maxAttempts) {
            this.totalNoOfAttempts = res;
          }
          else if (this.totalNoOfAttempts >= res && res !== -1 && maxAttempts >= res) {
            this.showErrorToast('cannot decrease max attempts if there are submissions');
            this.exerciseSetup.controls['maxAttempts'].setValue(this.totalNoOfAttempts);
          }
        }
      }
    });
    this.exerciseSetup.controls['gradingType'].valueChanges.pipe(filter((res: any) => res)).subscribe((res: any) => {
      if (res && this.exerciseBuilderService.exerciseBuilderMode === ExerciseBuilderModes.EDIT) {
        if (this.exerciseBuilderService.exerciseDetails.attemptsAndGradeConfig.gradingType === GradingType.NON_GRADABLE_WITHOUT_MARKS && res !== GradingType.NON_GRADABLE_WITHOUT_MARKS) {
          this.exerciseSetup.controls['gradingType'].setValue(GradingType.NON_GRADABLE_WITHOUT_MARKS);
          this.exerciseSetup.controls['gradingType'].updateValueAndValidity();
          this.showErrorToast('non gradable without marks cannot be changed');
        }
        if (this.exerciseBuilderService.exerciseDetails.attemptsAndGradeConfig.gradingType === GradingType.NON_GRADABLE_WITH_MARKS && res === GradingType.GRADABLE) {
          this.exerciseSetup.controls['gradingType'].setValue(GradingType.NON_GRADABLE_WITH_MARKS);
          this.exerciseSetup.controls['gradingType'].updateValueAndValidity();
          this.showErrorToast('non gradable with marks cannot be changed to Gradable');
        }
      }
    });
  }

  public async dateTimeEmitter(event: string, type: string): Promise<void> {
    (this.exerciseSetup.controls['duration'] as FormGroup).controls[type].setValue(dayjs(event));
  }

  idealTimeSetValue(res: number, idealField: 'idealMins' | 'idealHours') {
    const startDate = (this.exerciseSetup.controls['duration'] as FormGroup).controls['startDate'];
    const endDate = (this.exerciseSetup.controls['duration'] as FormGroup).controls['endDate'];
    if (!startDate?.value || !endDate?.value) {
      (this.exerciseSetup.controls['duration'] as FormGroup).controls[idealField].setValue(res);
    }
    let hour: number;
    let totalDurationTime: number;
    if (startDate?.value && endDate?.value) {
      const durationHours = (this.exerciseSetup.controls['duration'] as FormGroup).controls['durationHours'];
      const durationMins = (this.exerciseSetup.controls['duration'] as FormGroup).controls['durationMins'];
      hour = endDate.value.diff(startDate.value, 'hour', true);
      console.log(hour, 'difference');
      if (durationMins?.value && durationMins.value > 0) {
        totalDurationTime = (durationHours?.value || 0) + durationMins.value / 60;
        if (totalDurationTime <= hour) {
          (this.exerciseSetup.controls['duration'] as FormGroup).controls['durationHours'].setErrors(null);
        }
        else if (totalDurationTime > hour) {
          (this.exerciseSetup.controls['duration'] as FormGroup).controls['durationHours'].setErrors({ invalid: true });
          this.showErrorToast(' Time limit must be less than the time difference between start date and end date');
          return;
        }
      }
      (this.exerciseSetup.controls['duration'] as FormGroup).patchValue({
        idealHours: durationHours?.value,
        idealMins: durationMins?.value
      });

    }
  }

  scrollToId(id: string) {
    console.log("element id : ", id);
    this.scrollService.scrollToElementById(id);
  }

  scrollToElement(element: any) {
    this.scrollService.scrollToElement(element);
  }

  setupDynamicValidation(triggerControlName: string, formGroup: FormGroup, controlNames: string[]) {
    const triggerControl = formGroup.get(triggerControlName);
    if (triggerControl) {
      triggerControl.valueChanges.pipe(filter((res: any) => res)).subscribe((value) => {
        controlNames.forEach((control) => {
          const targetControl = formGroup.get(control);
          if (targetControl) {
            if (value) {
              targetControl.setValidators([Validators.required]);
            } else {
              targetControl.clearValidators();
            }
            targetControl.updateValueAndValidity();
          }
        });
      });
    }
  }
  changeAttempts() {
    if (this.exerciseSetup.get('attempts')?.value === 'Limited') {
      if (this.isDisable && this.totalNoOfAttempts === -1) {
        let multipleAttempts = 'unLimited';
        if (this.exerciseBuilderService.exerciseDetails.attemptsAndGradeConfig?.maxAttempts >= 2) {
          multipleAttempts = 'Limited';
        }
        if (multipleAttempts === 'unLimited') {
          this.exerciseSetup.controls['attempts'].setValue('unLimited');
          this.exerciseSetup.get('maxAttempts')?.clearValidators();
          this.exerciseSetup.get('maxAttempts')?.setValue(-1);
          this.exerciseSetup.get('overallGrade')?.setValidators(Validators.required);
          this.showErrorToast('cannot decrease max attempts if there are submissions');
          return;
        }
      }
      let maxAttempts = 2;
      if (this.exerciseBuilderService.exerciseDetails) {
        const maxAttemptsConfig = this.exerciseBuilderService.exerciseDetails.attemptsAndGradeConfig?.maxAttempts;
        maxAttempts = maxAttemptsConfig;
      }
      this.exerciseSetup.controls['maxAttempts'].setValue(maxAttempts);
      this.exerciseSetup.controls['maxAttempts'].updateValueAndValidity();
      this.exerciseSetup.get('maxAttempts')?.setValidators(Validators.required);
      this.exerciseSetup.get('overallGrade')?.setValidators(Validators.required);
    } else {
      this.exerciseSetup.get('maxAttempts')?.clearValidators();
      this.exerciseSetup.get('maxAttempts')?.setValue(-1);
      this.exerciseSetup.get('overallGrade')?.setValidators(Validators.required);
    }
  }
  mapExerciseTypeToKind = (exerciseType: string | null):
    ExerciseType => {
    switch (exerciseType) {
      case 'assignment':
        return ExerciseType.ASSIGNMENT;
      case 'quiz':
        return ExerciseType.QUIZ;
      case 'practice':
        return ExerciseType.PRACTICE;
      default:
        return ExerciseType.QUIZ;
    }
  };

  submitHandler(saveAsDraft = false) {
    const keysToDeleteForPractice = ['contentStatus', 'startDate', 'endDate', 'feedbackConfig'];
    let idealTime;
    if (this.exerciseTypeUrl === 'practice') {
      idealTime = (this.exerciseSetup.value.practiceIdealHours * 3600) + (this.exerciseSetup.value.practiceIdealMins * 60);
    } else {
      idealTime = (this.exerciseSetup.controls['duration'].value.idealHours * 3600) + (this.exerciseSetup.controls['duration'].value.idealMins * 60);
    }
    const payload: exerciseData = {
      title: this.exerciseSetup.value.title,
      description: this.exerciseSetup.value.description,
      status: saveAsDraft ? ExerciseStatus.DRAFT : this.status === ExerciseStatus.DRAFT ? ExerciseStatus.NOT_ATTACHED : this.status,
      kind: this.mapExerciseTypeToKind(this.exerciseTypeUrl),
      instructions: this.exerciseSetup.value.instruction,
      contentStatus: this.exerciseSetup.value.contentStatus,
      idealTimeRequired: idealTime,
      feedbackConfig: this.isRangeDisplay ? this.exerciseSetup.value.feedbackDetails : [],
      attemptsAndGradeConfig: {
        // passingCriteria: this.exerciseSetup.value.percentagePassing,
        maxAttempts: this.exerciseSetup.value.maxAttempts,
        gradingType: this.exerciseSetup.value.gradingType,
        overallGradeCalculation: this.exerciseSetup.value.overallGrade,
      },
      plagiarismConfig: {
        isPlagiarismEnabled: this.exerciseSetup.value.allowPlagiarism,
        showReportToLearner: this.exerciseSetup.value.plagiarismReport
      }
    };
    if (this.exerciseTypeUrl === 'practice') {
      keysToDeleteForPractice.forEach(key => delete (payload as any)[key]);
    }
    else {
      payload.startDate = this.exerciseSetup.controls['duration'].value.startDate.toISOString();
      payload.endDate = this.exerciseSetup.controls['duration'].value.endDate.toISOString();
      if (this.durationLimitExercise) {
        const durationTime = (this.exerciseSetup.controls['duration'].value.durationHours * 3600) + (this.exerciseSetup.controls['duration'].value.durationMins * 60);
        payload.duration = durationTime;
        payload.idealTimeRequired = durationTime;
      }
      if (this.isPassingCriteria) {
        payload.attemptsAndGradeConfig.passingCriteria = this.exerciseSetup.value.percentagePassing;
      }
    }
    if (this.exerciseBuilderService.exerciseBuilderMode === ExerciseBuilderModes.EDIT) {
      payload.exerciseId = this.exerciseBuilderService.exerciseId;
      this.exerciseBuilderService.updateExerciseForm(payload).subscribe(() => {
        if (saveAsDraft) {
          let url = '';
          if (this.exerciseBuilderService.redirectBack) {
            url = this.setUrl();
            location.replace(url);
            return;
          }
          this.router.navigate(['../../../exercise']);
        } else {
          this.router.navigate(['../../' + this.exerciseBuilderService.exerciseId + '/' + RoutingParams.MANIPULATE_EXERCISE], { relativeTo: this.activatedRoute, queryParams: { exerciseManipulationTab: 'exercise-projects' }, queryParamsHandling: 'merge' });

        }
      });
    }
    else {
      this.exerciseBuilderService.saveExerciseSetup(payload)
        .pipe(takeUntil(this.subjectToUnsubscribe))
        .subscribe((savedExerciseData: { exerciseId: string }) => {
          this.exerciseBuilderService.exerciseId = savedExerciseData.exerciseId;
          this.exerciseBuilderService.exerciseBuilderMode = ExerciseBuilderModes.EDIT;
          if (saveAsDraft) {
            if (this.exerciseBuilderService.redirectBack) {
              const url = this.setUrl();
              location.replace(url);
              return;
            }
            this.router.navigate(['../../../exercise'], { relativeTo: this.activatedRoute });
          } else {
            this.router.navigate(['../../' + savedExerciseData.exerciseId + '/' + RoutingParams.MANIPULATE_EXERCISE], { relativeTo: this.activatedRoute, queryParams: { exerciseManipulationTab: 'exercise-projects' }, queryParamsHandling: 'merge' });

          }
        });
    }
  }

  cancelExercise() {
    if (this.exerciseBuilderService.redirectBack) {
      let url = '';
      if (this.exerciseBuilderService.exerciseBuilderMode === ExerciseBuilderModes.EDIT) {
        url = this.setUrl();
      }
      else {
        url = `${this.exerciseBuilderService.redirectBack}`;
      }
      location.replace(url);
      return;
    }
    this.router.navigate(['../../../exercise'], { relativeTo: this.activatedRoute });
  }

  getExerciseId(decodedUrl: string) {
    const queryString = decodedUrl.split('?')[1];
    const queryParams = new URLSearchParams(queryString);
    return queryParams.get('exerciseId');
  }

  setExerciseId(decodedUrl: string) {
    const [queryUrl, queryString] = decodedUrl.split('?');
    const queryParams = new URLSearchParams(decodedUrl);
    queryParams.set('exerciseId', this.exerciseBuilderService.exerciseId);
    return queryUrl + '?' + queryString;
  }

  setUrl() {
    const decodedUrl = decodeURIComponent(this.exerciseBuilderService.redirectBack);
    const exerciseId = this.getExerciseId(decodedUrl);
    if (exerciseId) {
      const urlWithExerciseId = this.setExerciseId(decodedUrl);
      return urlWithExerciseId;
    }
    return `${this.exerciseBuilderService.redirectBack}&exerciseId=${this.exerciseBuilderService.exerciseId}`;
  }

  private editExerciseForm() {
    if (this.exerciseBuilderService.exerciseBuilderMode === ExerciseBuilderModes.EDIT) {
      this.patchExerciseForm(this.exerciseBuilderService.exerciseDetails);
      this.exerciseBuilderService.listenToExerciseDetailsState()
        .pipe(takeUntil(this.subjectToUnsubscribe))
        .subscribe(() => {
          this.patchExerciseForm(this.exerciseBuilderService.exerciseDetails);
        });
    }
  }

  // eslint-disable-next-line max-lines-per-function
  patchExerciseForm(data: ExerciseDetails) {
    const enableFeedback = data.feedbackConfig?.length === 0 ? false : (this.feedbackDetails(data.feedbackConfig), true);
    const enableMultipleAttempts = (data.attemptsAndGradeConfig?.maxAttempts >= 2 || data.attemptsAndGradeConfig?.maxAttempts === -1) ? true : false;
    const enablePassingCriteria = data.attemptsAndGradeConfig.passingCriteria ? true : false;
    const enableDuration = data.duration && data.duration > 0 ? true : false;
    let multipleAttempts = 'Limited';
    if (data.attemptsAndGradeConfig?.maxAttempts >= 2) {
      multipleAttempts = 'Limited';
    }
    else if (data.attemptsAndGradeConfig?.maxAttempts === -1) {
      multipleAttempts = 'unLimited';
    }
    const enablePlagiarism = data.plagiarismConfig.isPlagiarismEnabled ? true : false;
    this.exerciseSetup.patchValue({
      title: data.title,
      description: data.description,
      instruction: data.instructions,
      contentStatus: data.contentStatus,
      duration: {
        startDate: dayjs(data.startDate),
        endDate: dayjs(data.endDate),
        idealHours: Math.floor(data.idealTimeRequired / 3600),
        idealMins: Math.floor((data.idealTimeRequired % 3600) / 60),
        timeLimit: enableDuration ? 'Required' : 'Not required',
      },
      allowFeedback: enableFeedback,
      allowPlagiarism: enablePlagiarism,
      gradingType: data.attemptsAndGradeConfig?.gradingType,
      enablePassingCriteria: enablePassingCriteria,
      allowMultipleAttempts: enableMultipleAttempts,
      percentagePassing: data.attemptsAndGradeConfig?.passingCriteria,
      maxAttempts: data.attemptsAndGradeConfig?.maxAttempts,
      overallGrade: data.attemptsAndGradeConfig?.overallGradeCalculation,
      status: data.status
    });
    this.isRangeDisplay = enableFeedback;
    this.isPlagiarismDisplay = enablePlagiarism;
    this.isMultipleAttemptDisplay = enableMultipleAttempts;
    this.isPassingCriteria = enablePassingCriteria;
    this.durationLimitExercise = enableDuration;
    this.status = data.status;
    this.exerciseTypeUrl = data.kind;
    if (enablePlagiarism) {
      this.exerciseSetup.controls['plagiarismReport'].setValue(data.plagiarismConfig?.showReportToLearner);
      this.exerciseSetup.controls['plagiarismReport'].updateValueAndValidity();
    }
    if (enablePassingCriteria) {
      this.exerciseSetup.controls['percentagePassing'].setValue(data.attemptsAndGradeConfig?.passingCriteria);
    }
    if (enableDuration) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const mins = Math.floor(((data.duration)! % 3600) / 60);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const hours = Math.floor((data.duration)! / 3600);
      (this.exerciseSetup.controls['duration'] as FormGroup).patchValue({
        durationHours: hours,
        durationMins: mins,
        idealHours: Math.floor(data.idealTimeRequired / 3600),
        idealMins: Math.floor((data.idealTimeRequired % 3600) / 60)
      });
    }
    if (enableMultipleAttempts) {
      this.exerciseSetup.controls['attempts'].setValue(multipleAttempts);
      this.limitedAttempts = multipleAttempts == 'Limited' ? true : false;
      this.exerciseSetup.updateValueAndValidity();
    }
  }

  feedbackDetails(data: any) {
    this.optionArr.clear();
    data.forEach((val: any) => {
      this.addOption(val);
    });
  }

  addOption(value: any) {
    this.feedbackArr.push(this.appendFeedbackDetails(value));
  }

  appendFeedbackDetails(data: any) {
    return this.fb.group({
      startRange: data.startRange,
      endRange: data.endRange,
      feedback: data.feedback
    });
  }

  get optionArr() {
    return this.exerciseSetup.get("feedbackDetails") as FormArray;
  }

  showErrorToast(message: string): void {
    this.toastService.error(message, '', {
      positionClass: 'toast-top-center',
      closeButton: true,
      timeOut: 5000,
      extendedTimeOut: 5000,
      tapToDismiss: false
    });

  }

  ngOnDestroy() {
    this.subjectToUnsubscribe.complete();
    //this.exerciseBuilderService.destroyInstance();
  }

}