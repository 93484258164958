import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Themes } from 'src/app/enums/PlayerModes';
import { IAttemptProjectDetails } from 'src/app/models/attemptInterface';
import { ExercisePlayerService } from 'src/app/shared/modules/player/exercise-player.service';
import { ProjectPlayerService } from 'src/app/shared/modules/player/project-player.service';

@Component({
  selector: 'app-pre-submission',
  templateUrl: './pre-submission.component.html',
  styleUrls: ['./pre-submission.component.scss']
})
export class PreSubmissionComponent {
  @Output() emitBackToTest = new EventEmitter();
  @Input() attemptDataDetailsProjects!: IAttemptProjectDetails[];
  @Input() totalReviewed = 0;
  @Input() totalAnswered = 0;
  @Input() totalUnAnswered = 0;
  @Input() exercisePlayerService!: ExercisePlayerService | ProjectPlayerService;
  @Input() kind!: string;
  @Input() selectedTheme!: string;
  @Output() emitBackTheme = new EventEmitter();
  isExerciseSubmitEventTriggered = false;
  selectedStatus!: boolean;
  constructor(public projectPlayerService: ProjectPlayerService) { }

  ngOnInit() {
    if (this.selectedTheme) {
      this.toggleTheme(this.selectedTheme);
    }
    this.exercisePlayerService.listenValidationError().subscribe(() => {
      this.isExerciseSubmitEventTriggered = false;
    });
  }

  backToTest() {
    const backToTest = { shift: false };
    this.emitBackToTest.emit(backToTest);
  }

  submitExercise() {
    this.isExerciseSubmitEventTriggered = true;
    this.exercisePlayerService.submitExercise();
  }

  viewProject(index: number) {
    if (!this.isExerciseSubmitEventTriggered) {
      const backToTestWithIndex = { shift: false, projectIndex: index };
      this.emitBackToTest.emit(backToTestWithIndex);
    }
  }

  toggleTheme(theme: string) {
    this.emitBackTheme.emit(theme);
    this.selectedStatus = theme === 'dark' ? true : false;
    const selectedTheme = this.selectedStatus ? Themes.DARK : Themes.LIGHT;
    this.projectPlayerService.triggerThemeChange(selectedTheme);
    document.documentElement.setAttribute('data-theme', theme);
  }

  redirectToLumen() {
    const url = this.exercisePlayerService.setUrl(false);
    location.replace(url);
  }

}
