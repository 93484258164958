/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ProjectPlayerModes, Themes } from 'src/app/enums/PlayerModes';
import { ProjectPlayerService } from 'src/app/shared/modules/player/project-player.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CustomInputComponent } from '../custom-input/custom-input.component';
import { DbmsErrorResponse, ITestsRun, executionRun, exportFileToMonacoEditor, urlpayload } from 'src/app/models/projectInterface';
import * as monaco from 'monaco-editor';
import { compileAndRun, IinitErorr } from 'src/app/models/projectInterface';
import { LayoutModes } from 'src/app/enums/layoutModes';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/services/storage.service';
import { StorageKey } from 'src/app/enums/storageKey';
import { decodeJWTToken } from 'src/app/utils/application-initializer';
import { SkeletonLoaderType } from 'src/app/enums/skeletonui';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService, ErrorType } from 'src/app/services/common.service';

@Component({
  selector: 'app-file-editor',
  templateUrl: './file-editor.component.html',
  styleUrls: ['./file-editor.component.scss']
})
export class FileEditorComponent {
  @ViewChild('inputsidecontainer') inputsidecontainer!: ElementRef;
  @HostListener('window:beforeunload', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  unloadHandler(event: Event) {
    this.killPort();
  }
  @Input() mode!: ProjectPlayerModes;
  ProjectPlayerModes = ProjectPlayerModes;
  tabs: exportFileToMonacoEditor[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  activeFile!: any;
  decodedURL!: any;
  compilationEvents: any[] = [];
  isSanitized = false;
  editorOptions = {
    theme: 'vs-light',
    language: 'java',
    renderIndentGuides: true,
    fontSize: 16,
    tabSize: 4,
    automaticLayout: true,
    wordWrap: "on",
    scrollbar: {
      vertical: 'auto',
      horizontal: 'auto',
      verticalScrollbarSize: 12,
      horizontalScrollbarSize: 12,
      scrollByPage: true,
    },
    selectionHighlight: true,
    quickSuggestions: true,
    suggestFontSize: 14,
    minimap: {
      enabled: true,
      side: "right",// Position of the minimap: "right" or "left"
      showSlider: "mouseover",
      renderCharacters: true,
      maxColumn: 120, // Maximum column to render in the minimap
    },
    validate: true,
    readOnly: false,
    contextmenu: false,
    dropIntoEditor: {
      enabled: false
    }
  };
  layout = LayoutModes;
  unSubscribe = new Subject<void>();
  compileRunSubject!: Subject<boolean>;
  code = 'function x() {\nconsole.log("Hello world!");\n}';
  urldecoded: any;
  SkeletonLoaderType = SkeletonLoaderType;
  isFirstCompile = true;
  base64String!: any;
  isResultDisplayed = false;
  iscompileDisabled = false;
  displayUploader = false;
  fileUploaderWidth = '0%';
  dataInsertedWidth = '0%';
  initFile: File | null = null;

  constructor(public projectPlayerService: ProjectPlayerService, private modal: NgbModal, private storageService: StorageService, private sanitizer: DomSanitizer, private commonService: CommonService) {
  }
  modalRef!: NgbModalRef;

  ngOnInit() {
    this.tabs = this.projectPlayerService.tabs;
    this.activeFile = this.tabs[0];
    this.projectPlayerService.setSubmitState(false);
    this.loadDependencies();
  }

  loadDependencies() {
    this.projectPlayerService.listenAddTabToMonacoEditor().pipe(takeUntil(this.unSubscribe)).subscribe((obj) => {
      this.displayCodeInEditor(obj);
    });
    this.projectPlayerService.listenThemeChange().pipe(takeUntil(this.unSubscribe)).subscribe((obj) => {
      this.editorOptions.theme = obj;
      this.toggleTheme(obj);
    });
    this.projectPlayerService.clearTab$.pipe(takeUntil(this.unSubscribe)).subscribe(() => {
      this.clearEditorVariables();
    });
    this.projectPlayerService.listenDisplayResultFile().pipe(takeUntil(this.unSubscribe)).subscribe((obj: string) => {
      const pngData = obj;
      const prefix = "data:";
      const addPrefix = pngData.startsWith(prefix);
      if (addPrefix) {
        this.base64String = pngData;
      }
      else {
        this.base64String = `data:image/png;base64,${obj}`;
      }
      this.isResultDisplayed = true;
    });
    this.projectPlayerService.initSqlUploader$.pipe(takeUntil(this.unSubscribe)).subscribe(async (res: File | null) => {
      this.displayUploader = true;
      this.initFile = res;
      if (this.initFile) {
        const fileResponse = await this.projectPlayerService.uploadFile(this.initFile);
        if (fileResponse.message === 'Sql File uploaded successfully') {
          this.fileUploaderWidth = '100%';
        } else {
          this.displayUploader = false;
        }
      }
    });
    this.projectPlayerService.listenValidationError().pipe(takeUntil(this.unSubscribe)).subscribe(async (res: IinitErorr) => {
      if (res.errId === 'ERROR_EXECUTING_QUERY') {
        const message = this.commonService.translateText('projectBank.projectPlayer.queryRunInitError',);
        this.commonService.showToast(message, ErrorType.ERROR);
        this.displayUploader = false;
      }
    });
    this.projectPlayerService.deleteFileListener$.pipe(takeUntil(this.unSubscribe)).subscribe((path: string | undefined) => {
      if (path) {
        this.removeObjectByPath(this.tabs, path);
        this.removeObjectByPath(this.projectPlayerService.tabs, path);
      }
    });
  }

  removeObjectByPath(arr: exportFileToMonacoEditor[], path: string): exportFileToMonacoEditor[] {
    return arr.filter(file => file.path !== path);
  }

  getButtonLabel(): string {
    if (this.projectPlayerService.selectedLanguage === 'DBMS') {
      return 'Run Query';
    }
    else if (this.projectPlayerService.selectedArchetype === 'JAVA17_SPRINGBOOT') {
      return 'Launch App';
    }
    return 'Compile & Run';
  }

  toggleTheme(theme: Themes) {
    this.editorOptions = {
      theme: theme,
      language: 'java',
      renderIndentGuides: true,
      fontSize: 16,
      tabSize: 4,
      automaticLayout: true,
      wordWrap: "on",
      scrollbar: {
        vertical: 'auto',
        horizontal: 'auto',
        verticalScrollbarSize: 12,
        horizontalScrollbarSize: 12,
        scrollByPage: true,
      },
      selectionHighlight: true,
      quickSuggestions: true,
      suggestFontSize: 14,
      minimap: {
        enabled: true,
        side: "right",// Position of the minimap: "right" or "left"
        showSlider: "mouseover",
        renderCharacters: true,
        maxColumn: 120, // Maximum column to render in the minimap
      },
      validate: true,
      readOnly: false,
      contextmenu: false,
      dropIntoEditor: {
        enabled: false
      }
    };
  }
  customInput() {
    this.modalRef = this.modal.open(CustomInputComponent, { backdrop: 'static', centered: true, animation: true, windowClass: "test-case-modal" });
    this.modalRef.componentInstance.projectPlayerService = this.projectPlayerService;
    this.modalRef.componentInstance.closeCustom.subscribe(() => {
      this.modalRef.close();
    });
  }

  displayCodeInEditor(obj: exportFileToMonacoEditor) {
    const existingTab = this.tabs.find((tab) => tab.path === obj.path);
    if (existingTab) {
      this.activeFile = existingTab;
      return;
    }
    this.tabs.push(obj);
    this.activeFile = obj;
    this.projectPlayerService.tabs = this.tabs;
  }

  onEditorInit(editor: monaco.editor.IStandaloneCodeEditor) {
    editor.onDidChangeModelContent((modelChange) => {
      if (!modelChange.isFlush) {
        this.projectPlayerService.setSubmitState(true);
      }
      this.tabs.forEach(tab => {
        if (tab.path === this.activeFile.path) {
          this.activeFile.code = editor.getValue();
          tab.code = this.activeFile.code;
        }
      });
    });
    /*
    this code is for futire reference of selection of partial lines in monaco editor
    */
    // editor.onDidChangeCursorSelection((selectionChanfg))
  }

  close(event: MouseEvent, toRemove: string) {
    const removeIndex = this.tabs.findIndex((tab) => tab.path === toRemove);
    if (removeIndex !== -1) {
      this.tabs.splice(removeIndex, 1);
      const currentIndex = this.tabs.findIndex((tab) => tab.path === this.activeFile);
      if (currentIndex !== -1) {
        this.activeFile = this.tabs[currentIndex];
      } else if (this.tabs.length > 0) {
        this.activeFile = this.tabs[this.tabs.length - 1];
      } else {
        this.activeFile = null;
      }
    }
    this.projectPlayerService.triggerHighLightActiveFile(this.activeFile);
    event.preventDefault();
    event.stopImmediatePropagation();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sanitizeFile(event: any) {
    this.isSanitized = event.target.checked;
  }

  // eslint-disable-next-line max-lines-per-function
  async compileAndRun() {
    this.iscompileDisabled = true;
    //calculating runtime width based on viewport
    const editorWidth = (this.inputsidecontainer.nativeElement.clientWidth / window.innerWidth) * 100;
    const style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = `.layout-side >.inputside-container {
    width:${editorWidth}vw !important;
    }`;
    document.getElementsByTagName('head')[0].appendChild(style);
    this.throttleTimeOut();
    this.clearEditorVariables(false);
    if (this.projectPlayerService.layout === this.layout.NONE) {
      this.projectPlayerService.layout = this.mode === ProjectPlayerModes.EVALUATION ? this.layout.DOWN : this.layout.SIDE;
    }
    if (this.projectPlayerService.selectedArchetype === 'JAVA17_SPRINGBOOT' && !this.isFirstCompile) {
      this.killPort();
    } else if (this.projectPlayerService.selectedArchetype === 'JAVA17_SPRINGBOOT') {
      this.isFirstCompile = false;
    }
    this.compileAndRunListeners();
    const savedFiles = await this.projectPlayerService.saveAllFiles();
    if (savedFiles) {
      if (this.projectPlayerService.getSubmitState() && this.mode === ProjectPlayerModes.ATTEMPT) {
        this.projectPlayerService.updateProjectArchetypesMap(this.projectPlayerService.projectId, this.projectPlayerService.selectedArchetype);
        if (!this.projectPlayerService.getAnswerStatus(this.projectPlayerService.projectId)) {
          const updateProjectUnSubscribe = this.projectPlayerService.updateProjectStatus().subscribe(() => {
            this.projectPlayerService.setAnswerStatus(this.projectPlayerService.projectId, true);
            updateProjectUnSubscribe.unsubscribe();
          });
          this.projectPlayerService.setSubmitState(false);
        }
      }
      this.compilationEvents = [];
      const payload: compileAndRun = {
        [this.mode !== ProjectPlayerModes.PRACTISE ? 'projectId' : 'practiceId']: this.projectPlayerService.projectId,
        isSanitized: this.isSanitized,
        archetypeId: this.projectPlayerService.selectedArchetype,
        language: this.projectPlayerService.selectedLanguage,
        testCaseTypes: this.projectPlayerService.blackBox,
        ...(this.projectPlayerService.exerciseIdTry && { exerciseId: this.projectPlayerService.exerciseIdTry }),
      };
      if (this.projectPlayerService.customInput) {
        payload.customInput = this.projectPlayerService.customInput;
      }
      const loading = "Loading...";
      this.compilationEvents.push(loading);
      this.projectPlayerService.compileAndRun(payload).pipe(takeUntil(this.compileRunSubject)).subscribe((data: executionRun) => {
        if (data.message === 'success') {
          this.compilationEvents = [];
        }
      });
    }
  }

  throttleTimeOut() {
    const intervalTime = 1000; //ms
    let countDown = 0;
    const interval = setInterval(() => {
      if (!this.iscompileDisabled) {
        clearInterval(interval);
      }
      if (countDown === 10) {
        this.iscompileDisabled = false;
        clearInterval(interval);
      }
      countDown++;
      console.log(countDown, 'interval');
    }, intervalTime);
  }

  killPort() {
    if (!this.projectPlayerService.storedUrlToken) {
      return;
    }
    const payload: urlpayload = {
      urlToken: this.projectPlayerService.storedUrlToken
    };
    this.projectPlayerService.kill_port(payload);
  }
  // eslint-disable-next-line max-lines-per-function
  compileAndRunListeners() {
    this.compileRunSubject = new Subject();
    this.projectPlayerService.startingExecution().pipe(takeUntil(this.compileRunSubject)).subscribe((data: executionRun) => {
      this.compilationEvents = [];
      this.compilationEvents.push(data.message);
    });
    this.projectPlayerService.default_run_result().pipe(takeUntil(this.compileRunSubject)).subscribe((data: executionRun) => {
      if (data.message !== '') {
        const output = 'Output:-';
        if (data.status === 'error' && data.message === 'Could not run the program. Execution timed out') {
          const message = 'Could not run the program.';
          const timeOut = 'Execution timed out';
          this.compilationEvents.push(output, message, timeOut);
          return;
        }
        this.compilationEvents.push(output, data.message);
      }
    });
    this.projectPlayerService.compilation_success().pipe(takeUntil(this.compileRunSubject)).subscribe((data: executionRun) => {
      this.compilationEvents.push(data.message);
    });
    this.projectPlayerService.userBlackBoxTests().pipe(takeUntil(this.compileRunSubject)).subscribe((userBlackBoxTestCases: ITestsRun) => {
      this.testCasesMessageOnCompileAndRun(userBlackBoxTestCases);
    });
    this.projectPlayerService.evaluatorBlackBoxTests().pipe(takeUntil(this.compileRunSubject)).subscribe((evaluatorBlackBoxTestCases: ITestsRun) => {
      this.testCasesMessageOnCompileAndRun(evaluatorBlackBoxTestCases);
    });
    this.projectPlayerService.program_executed().pipe(takeUntil(this.compileRunSubject)).subscribe((data: executionRun) => {
      this.iscompileDisabled = false;
      this.compilationEvents.push(data.message);
    });
    this.projectPlayerService.compile_run_tests_failur().pipe(takeUntil(this.compileRunSubject)).subscribe((data: executionRun) => {
      const metaData = `compile run tests ${data.message}`;
      this.compilationEvents.push(metaData);
    });
    this.projectPlayerService.program_execution_failed().pipe(takeUntil(this.compileRunSubject)).subscribe(() => {
      const metaData = `Program Execution Failed`;
      this.compilationEvents.push(metaData);
      this.iscompileDisabled = false;
    });
    this.projectPlayerService.extract_zip_failure().pipe(takeUntil(this.compileRunSubject)).subscribe(() => {
      const metaData = "Project Load Failure";
      this.compilationEvents.push(metaData);
    });
    this.projectPlayerService.project_zip_failure().pipe(takeUntil(this.compileRunSubject)).subscribe(() => {
      const metaData = "Project Save Failed";
      this.compilationEvents.push(metaData);
    });
    this.projectPlayerService.compilation_failed().pipe(takeUntil(this.compileRunSubject)).subscribe((data: executionRun) => {
      const metaData = `Project Compilation Failed - ${data.message}`;
      this.iscompileDisabled = false;
      this.compilationEvents.push(metaData);
    });
    this.projectPlayerService.testExecutionFailed().pipe(takeUntil(this.compileRunSubject)).subscribe((data: executionRun) => {
      const metaData = `${data.message}`;
      this.compilationEvents.push(metaData);
    });
    this.projectPlayerService.listenDataBaseConnected().pipe(takeUntil(this.compileRunSubject)).subscribe(() => {
      const metaData = "DataBase Connected";
      this.compilationEvents.push(metaData);
    });
    this.projectPlayerService.listenQueryExecuted().pipe(takeUntil(this.compileRunSubject)).subscribe((queryData: any) => {
      const metaData = "Query Executed in " + queryData.executionTime;
      this.compilationEvents.push(metaData);
      if (Array.isArray(queryData.result[0])) {
        this.queryExecutedTableIntegration(queryData.result[0]);
      }
      else if (this.projectPlayerService.selectedArchetype === "PLSQL") {
        if (typeof queryData.result[0] === 'string') {
          this.compilationEvents.push(...queryData.result);
        }
      }
      else if (typeof queryData.result[0] === 'string') {
        this.compilationEvents.push(queryData.result[0]);
      }
    });
    this.projectPlayerService.listenQueryExecutionFailed().pipe(takeUntil(this.compileRunSubject)).subscribe((data: { message: string }) => {
      this.compilationEvents.push(data.message);
    });
    this.projectPlayerService.dbmsExecutionSuccess().pipe(takeUntil(this.compileRunSubject)).subscribe(() => {
      const metaData = "DBMS Executed Successfully";
      this.compilationEvents.push(metaData);
    });
    this.projectPlayerService.dbmsExecutionFailed().pipe(takeUntil(this.compileRunSubject)).subscribe((response: DbmsErrorResponse) => {
      const errorMessage = response.message;
      const metaData = "DBMS Execution Failed";
      this.compilationEvents.push(errorMessage, metaData);
    });
    this.projectPlayerService.userWhiteBoxTests().pipe(takeUntil(this.compileRunSubject)).subscribe((userBlackBoxTestCases: ITestsRun) => {
      this.testCasesMessageOnCompileAndRun(userBlackBoxTestCases);
    });
    this.projectPlayerService.evaluatorWhiteBoxTests().pipe(takeUntil(this.compileRunSubject)).subscribe((evaluatorBlackBoxTestCases: ITestsRun) => {
      this.testCasesMessageOnCompileAndRun(evaluatorBlackBoxTestCases);
    });
    const urlUnsubscribe$ = this.projectPlayerService.link_generated().subscribe((url: urlpayload) => {
      const urlToken = url.urlToken;
      this.projectPlayerService.storedUrlToken = urlToken;
      this.decodedURL = decodeJWTToken(urlToken);
      const launchURL = this.decodedURL.url;
      window.open(launchURL);
      urlUnsubscribe$.unsubscribe();
    });
  }

  queryExecutedTableIntegration(queryData: any) {
    if (queryData.length === 0) {
      console.error('QueryData is Empty');
      return;
    }
    this.compilationEvents.push(queryData);
  }

  testCasesMessageOnCompileAndRun(testCases: ITestsRun) {
    let title = '';
    let Status = '';
    let violation = '';
    if (testCases.status === 'failed' || !testCases.status) {
      title = `Title: ${testCases.title ?? 'NA'}`;
      Status = `Status: ${testCases.status}`;
      violation = `Violation: ${testCases.violation}`;
      this.compilationEvents.push(title);
      this.compilationEvents.push(Status);
      this.compilationEvents.push(violation);
    }
    else {
      title = `Title: ${testCases.title ?? 'NA'}`;
      Status = `Status: ${testCases.status}`;
      this.compilationEvents.push(title);
      this.compilationEvents.push(Status);
    }
  }

  activateTab(tab: exportFileToMonacoEditor) {
    this.activeFile = tab;
    this.projectPlayerService.triggerHighLightActiveFile(this.activeFile);
  }

  closeOutput() {
    this.projectPlayerService.layout = this.layout.NONE;
  }

  ngOnDestroy() {
    this.unSubscribe.next();
    this.unSubscribe.unsubscribe();
    if (this.projectPlayerService.selectedArchetype === 'JAVA17_SPRINGBOOT') {
      this.killPort();
      console.log('destroyed');
    }
    console.log('file editor destroy');
    this.tabs = [];
    this.activeFile = {};
    this.compilationEvents = [];
  }

  clearEditorVariables(setSubmitStateToFalse = true) {
    this.compilationEvents = [];
    this.isResultDisplayed = false;
    if (setSubmitStateToFalse) {
      this.tabs = [];
      this.projectPlayerService.setSubmitState(false);
    }
    if (this.compileRunSubject && !this.compileRunSubject.closed) {
      this.compileRunSubject.next(true);
      this.compileRunSubject.unsubscribe();
    }
  }

  markForReview(projectId: string) {
    const reviewed = this.projectPlayerService.getMarkedForReview(projectId);
    const obj = {
      attemptId: this.projectPlayerService.attemptId,
      projectId: this.projectPlayerService.projectId,
      isMarkedForReview: !reviewed,
    };
    this.projectPlayerService.projectMarkedForReview(obj).subscribe(() => {
      this.projectPlayerService.setMarkedForReview(projectId, obj.isMarkedForReview);
    });
  }
  navigateToViewPage() {
    if (this.projectPlayerService.selectedArchetype === 'WEB_BASIC') {
      // const commonUtils = new CommonUtils();
      let baseUrl = '';
      // const configDomain = commonUtils.getDomainFromUrl();
      // const isLocal = (configDomain === "localhost");
      baseUrl = environment.apiBaseUrl;
      let userId = ' ';
      try {
        userId = this.storageService.get(StorageKey.UserId);
      }
      catch {
        userId = "userId";
      }
      let pagePath = ' ';
      if (this.isSanitized) {
        pagePath = `solution/${this.activeFile.name}`;
      } else {
        pagePath = this.activeFile.path;
      }
      const facultyUrl = `${baseUrl}api/code-runner-service/content/${this.projectPlayerService.projectId}/web/${pagePath}`;
      const practiceUrl = `${baseUrl}api/code-runner-service/content/practice/${userId}/${this.projectPlayerService.projectId}/web/${pagePath}`;
      const studentUrl = `${baseUrl}api/code-runner-service/content/${this.projectPlayerService.exerciseId}/${userId}/${this.projectPlayerService.attemptId}/${this.projectPlayerService.projectId}/web/${pagePath}`;
      if (this.mode === this.ProjectPlayerModes.ATTEMPT) {
        window.open(studentUrl);
      } else if (this.mode === this.ProjectPlayerModes.EVALUATION || this.mode === this.ProjectPlayerModes.TRY || this.mode === this.ProjectPlayerModes.EDIT) {
        if (this.projectPlayerService.exerciseIdTry) {
          const facultyUrlWithExercise = `${baseUrl}api/code-runner-service/content/${this.projectPlayerService.exerciseIdTry}/${this.projectPlayerService.projectId}/web/${pagePath}`;
          window.open(facultyUrlWithExercise);
          return;
        }
        window.open(facultyUrl);
      }
      else if (this.mode === this.ProjectPlayerModes.PRACTISE) {
        window.open(practiceUrl);
      }
      return;
    }
    this.projectPlayerService.getResult.next(this.isSanitized);
  }

  closeResult() {
    this.isResultDisplayed = false;
  }

  async deleteSqlFile() {
    this.displayUploader = false;
    this.projectPlayerService.deleteSqlTrigger();
  }

  trigeredFromSql(event: any) {
    if (event) {
      this.deleteSqlFile();
      return;
    }
    this.displayUploader = false;
  }

}
